import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import DateSelectField from '../../../components/compound/DateSelectField';
import MultiTextField from '../../../components/compound/MultiTextField';
import RadioField from '../../../components/compound/RadioField';
import SelectField from '../../../components/compound/SelectField';
import TextField from '../../../components/compound/TextField';
import Section from '../../../components/layout/Section';
import { useValidation } from '../../../hooks';
import { VisaApplicationType } from '../../../types/visa/applicationType';
import { isThisVisaChange, isThisVisaExtension } from '../../../utils/visaApplicationHelper';
import { TYPE_OF_CONTRACT_OPTIONS_FOR_TECHNICAL } from '../../../constants/options/contract';
import { DoesApplicantHavePositionOrTitle, ScheduledPeriodOfEmployment, StartDateOfEmployment, START_DATE_OF_EMPLOYMENT_UNDECIDED_OTHER_VALUE } from '../../../types/uncommonFormParts/formN/value';
import { START_DATE_OF_EMPLOYMENT_UNDECIDED_OPTIONS, TYPE_OF_WORK_OPTIONS } from '../../../constants/options/work';
import { FormN } from '../../../types/uncommonFormParts/formN/data';
import { SupplementaryInfo } from '../../../types/uncommonFormParts/supplementaryInfo';
import TimePeriodSelectField from '../../../components/compound/TimePeriodSelectField';

interface EmploymentConditionsSectionProp {
  visaApplicationType: VisaApplicationType;
  formN: FormN | null;
  supplementaryInfo: SupplementaryInfo | null;
  onChangeFormN: (formN: Partial<FormN>) => void;
  onChangeSupplementaryInfo: (data: Partial<SupplementaryInfo>) => void;
  onSectionReadinessChange: (isSectionReady: boolean) => void;
  isEmptyRequiredValueError: boolean;
};

const EmploymentConditionsSection: FunctionComponent<EmploymentConditionsSectionProp> = ({
  visaApplicationType,
  formN,
  supplementaryInfo,
  onChangeFormN,
  onChangeSupplementaryInfo,
  onSectionReadinessChange,
  isEmptyRequiredValueError,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'employmentConditionsSection' });
  const { t : tWithoutPrefix } = useTranslation();
  const validation = useValidation();

  const [typeOfContract, setTypeOfContract] = useState<string>('');
  const [scheduledPeriodOfEmployment, setScheduledPeriodOfEmployment] = useState<string>('');
  const [scheduledPeriodOfEmploymentFixedYear, setScheduledPeriodOfEmploymentFixedYear] = useState<string>('');
  const [scheduledPeriodOfEmploymentFixedMonth, setScheduledPeriodOfEmploymentFixedMonth] = useState<string>('');
  const [startDateOfEmployment, setStartDateOfEmployment] = useState<string>('');
  const [startDateOfEmploymentDecided, setStartDateOfEmploymentDecided] = useState<string>('');
  const [startDateOfEmploymentUndecided, setStartDateOfEmploymentUndecided] = useState<string>('');
  const [startDateOfEmploymentUndecidedOther, setStartDateOfEmploymentUndecidedOther] = useState<string>('');
  const [monthlyCompensionBeforeTaxYen, setMonthlyCompensionBeforeTaxYen] = useState<string>('');
  const [yearsOfBusinessExperience, setYearsOfBusinessExperience] = useState<string>('');
  const [doesApplicantHavePositionTitle, setDoesApplicantHavePositionTitle] = useState<string>('');
  const [nameOfPositionTitle, setNameOfPositionTitle] = useState<string>('');
  const [mainOccupationTypeOfWork, setMainOccupationTypeOfWork] = useState<string>('');
  const [otherOccupation1, setOtherOccupation1] = useState<string>('');
  const [otherOccupation2, setOtherOccupation2] = useState<string>('');
  const [detailsOfApplicantsWork, setDetailsOfApplicantsWork] = useState<string>('');

  const [startDateOfEmploymentUndecidedOtherError, setStartDateOfEmploymentUndecidedOtherError] = useState<string>('');
  const [monthlyCompensionBeforeTaxYenError, setMonthlyCompensionBeforeTaxYenError] = useState<string>('');
  const [yearsOfBusinessExperienceError, setYearsOfBusinessExperienceError] = useState<string>('');
  const [nameOfPositionTitleError, setNameOfPositionTitleError] = useState<string>('');
  const [detailsOfApplicantsWorkError, setDetailsOfApplicantsWorkError] = useState<string>('');
  
  const getEmptyError = (val: any) => (isEmptyRequiredValueError && !!!val)? tWithoutPrefix("inputAlert.field"): '';

  //Currently, the item names for this section are common between visa extension and change, 
  //so using the same function.
  const saveFormN = (data: Partial<FormN>) => {
    onChangeFormN(data);
  }

  const saveSupplementaryInfo = (data: Partial<SupplementaryInfo>) => {
    onChangeSupplementaryInfo(data);
  }

  useEffect(() => {
    if (!formN) 
      return;

    setTypeOfContract(formN["type_of_contract"] || '');
    setScheduledPeriodOfEmployment(formN["period_of_work"] || '');
    setScheduledPeriodOfEmploymentFixedYear(formN["period_of_work_fixed_period_year"] || '');
    setScheduledPeriodOfEmploymentFixedMonth(formN["period_of_work_fixed_period_month"] || '');
    setStartDateOfEmploymentDecided(formN["start_date_of_employment"] || '');
    setStartDateOfEmploymentUndecided(formN["start_date_of_employment_if_it_is_undecided"] || '');
    setStartDateOfEmploymentUndecidedOther(formN["start_date_of_employment_if_it_is_undecided_other"] || '');
    setMonthlyCompensionBeforeTaxYen(formN["salary_reward"] || '');
    setYearsOfBusinessExperience(formN["business_experience"] || '');
    setDoesApplicantHavePositionTitle(formN["position"] || '');
    setNameOfPositionTitle(formN["position_name"] || '');
    setMainOccupationTypeOfWork(formN["occupation_main_type_of_work"] || '');
    setOtherOccupation1(formN["occupation_other_occupation_1"] || '');
    setOtherOccupation2(formN["occupation_other_occupation_2"] || '');
    setDetailsOfApplicantsWork(formN["details_of_activities"] || '');
  }, [formN]);

  useEffect(() => {
    if (!supplementaryInfo)
      return;

    setStartDateOfEmployment(supplementaryInfo["start_date_of_employment_kind"] || '');
  }, [supplementaryInfo]);


  useEffect(() => {
    const conditionallyRequiredFields = [];

    switch (scheduledPeriodOfEmployment) {
      case ScheduledPeriodOfEmployment.Fixed:
        conditionallyRequiredFields.push(scheduledPeriodOfEmploymentFixedMonth);
        break;
      case ScheduledPeriodOfEmployment.NonFixed:
        break;
    }

    switch (startDateOfEmployment) {
      case StartDateOfEmployment.Decided:
        conditionallyRequiredFields.push(startDateOfEmploymentDecided);
        break;
      case StartDateOfEmployment.Undecided:
        conditionallyRequiredFields.push(startDateOfEmploymentUndecided);
        if (startDateOfEmploymentUndecided === START_DATE_OF_EMPLOYMENT_UNDECIDED_OTHER_VALUE)
          conditionallyRequiredFields.push(startDateOfEmploymentUndecidedOther);
        break;
    }

    switch (doesApplicantHavePositionTitle) {
      case DoesApplicantHavePositionOrTitle.Yes:
        conditionallyRequiredFields.push(nameOfPositionTitle);
        break;
      case DoesApplicantHavePositionOrTitle.No:
        break;
    }

    const areAllRequiredFiedsFilled = [
      typeOfContract,
      scheduledPeriodOfEmployment,
      startDateOfEmployment,
      monthlyCompensionBeforeTaxYen,
      yearsOfBusinessExperience,
      doesApplicantHavePositionTitle,
      mainOccupationTypeOfWork,
      detailsOfApplicantsWork,
      ...conditionallyRequiredFields
    ].every(val => !!val);

    const areThereNoErrors = [
      startDateOfEmploymentUndecidedOtherError,
      monthlyCompensionBeforeTaxYenError,
      yearsOfBusinessExperienceError,
      nameOfPositionTitleError,
      detailsOfApplicantsWorkError
    ].every(val => !val);

    onSectionReadinessChange(
      areAllRequiredFiedsFilled && areThereNoErrors
    );
  }, [
    typeOfContract,
    scheduledPeriodOfEmployment,
    scheduledPeriodOfEmploymentFixedYear,
    scheduledPeriodOfEmploymentFixedMonth,
    startDateOfEmployment,
    startDateOfEmploymentDecided,
    startDateOfEmploymentUndecided,
    startDateOfEmploymentUndecidedOther,
    monthlyCompensionBeforeTaxYen,
    yearsOfBusinessExperience,
    doesApplicantHavePositionTitle,
    nameOfPositionTitle,
    mainOccupationTypeOfWork,
    otherOccupation1,
    otherOccupation2,
    detailsOfApplicantsWork,
    startDateOfEmploymentUndecidedOtherError,
    monthlyCompensionBeforeTaxYenError,
    yearsOfBusinessExperienceError,
    nameOfPositionTitleError,
    detailsOfApplicantsWorkError
  ])


  return (
    <Section>
     
      {/* Type of contract */}
      <SelectField
        required
        label={t('typeOfContract')}
        placeholder={tWithoutPrefix('placeholder.select')}
        options={TYPE_OF_CONTRACT_OPTIONS_FOR_TECHNICAL}
        value={typeOfContract}
        error={getEmptyError(typeOfContract)}
        onValueChange={val => {
          setTypeOfContract(val);
          saveFormN({ "type_of_contract": val });
        }}
      />

      {/* Scheduled period of employment */}
      <RadioField
        required
        label={t('scheduledPeriodOfEmployment')}
        options={[
          {
            label: t('fixed'),
            value: ScheduledPeriodOfEmployment.Fixed,
          },
          {
            label: t('nonFixed'),
            value: ScheduledPeriodOfEmployment.NonFixed,
          },
        ]}
        value={scheduledPeriodOfEmployment}
        error={getEmptyError(scheduledPeriodOfEmployment)}
        onValueChange={val => {
          let data: Partial<FormN> = {};
          data["period_of_work"] = val;

          if (scheduledPeriodOfEmployment === ScheduledPeriodOfEmployment.NonFixed) {
            setScheduledPeriodOfEmploymentFixedMonth('');
            data["period_of_work_fixed_period_year"] = '';
            data["period_of_work_fixed_period_month"] = '';
          }

          setScheduledPeriodOfEmployment(val);
          saveFormN(data);
        }}
      />

      {/* Scheduled period of employment (Fixed) */}
      { scheduledPeriodOfEmployment === ScheduledPeriodOfEmployment.Fixed &&
        <TimePeriodSelectField
          required
          label={t('scheduledPeriodOfEmploymentFixed')}
          yearValue={scheduledPeriodOfEmploymentFixedYear}
          monthValue={scheduledPeriodOfEmploymentFixedMonth}
          error={
            getEmptyError(scheduledPeriodOfEmploymentFixedMonth) ||
            getEmptyError(scheduledPeriodOfEmploymentFixedYear)
          }
          onYearValueChange={val => {
            setScheduledPeriodOfEmploymentFixedYear(val);
            saveFormN({ "period_of_work_fixed_period_year": val });
          }}
          onMonthValueChange={val => {
            setScheduledPeriodOfEmploymentFixedMonth(val);
            saveFormN({ "period_of_work_fixed_period_month": val });
          }}
        />
      }

      {/* Start date of employment */}
      <RadioField
        required
        label={t('startDateOfEmployment')}
        options={[
          {
            label: t('decided'),
            value: StartDateOfEmployment.Decided,
          },
          {
            label: t('undecided'),
            value: StartDateOfEmployment.Undecided,
          },
        ]}
        value={startDateOfEmployment}
        error={getEmptyError(startDateOfEmployment)}
        onValueChange={val => {
          let data: Partial<FormN> = {};

          if (val === StartDateOfEmployment.Decided) {
            setStartDateOfEmploymentUndecided('');
            setStartDateOfEmploymentUndecidedOther('');
            setStartDateOfEmploymentUndecidedOtherError('');
            data["start_date_of_employment_if_it_is_undecided"] = '';
            data["start_date_of_employment_if_it_is_undecided_other"] = '';
          }

          if (val === StartDateOfEmployment.Undecided) {
            setStartDateOfEmploymentDecided('');
            data["start_date_of_employment"] = '';
          }

          saveFormN(data);
          saveSupplementaryInfo({ "start_date_of_employment_kind": val });
          setStartDateOfEmployment(val);
        }}
      />

      {/* Start date of employment (Decided) */}
      { startDateOfEmployment === StartDateOfEmployment.Decided && (
        <DateSelectField
          required
          label={t('startDateOfEmploymentDecided')}
          minDate={new Date(new Date().getFullYear() - 5, 0, 1)}
          maxDate={new Date(new Date().getFullYear() + 5, 11, 31)}
          value={startDateOfEmploymentDecided}
          error={getEmptyError(startDateOfEmploymentDecided)}
          onValueChange={val => {
            if (!val)
              return;

            setStartDateOfEmploymentDecided(val);
            saveFormN({ "start_date_of_employment": val });
          }}
        />
      )}

      {/* Start date of employment (Undecided) */}
      { startDateOfEmployment === StartDateOfEmployment.Undecided && (
        <SelectField
          required
          label={t('startDateOfEmploymentUndecided')}
          placeholder={tWithoutPrefix('placeholder.select')}
          options={START_DATE_OF_EMPLOYMENT_UNDECIDED_OPTIONS}
          value={startDateOfEmploymentUndecided}
          error={getEmptyError(typeOfContract)}
          onValueChange={val => {
            setStartDateOfEmploymentUndecided(val);
            saveFormN({ "start_date_of_employment_if_it_is_undecided": val });
          }}
        />
      )}

      {/* Start date of employment (Undecided) (Other) */}
      { startDateOfEmployment === StartDateOfEmployment.Undecided && 
        startDateOfEmploymentUndecided === START_DATE_OF_EMPLOYMENT_UNDECIDED_OTHER_VALUE && (
        <TextField
          required
          label={t('startDateOfEmploymentUndecidedOther')}
          value={startDateOfEmploymentUndecidedOther}
          error={startDateOfEmploymentUndecidedOtherError}
          maxLength={172}
          validators={[validation.createLengthValidator(172)]}
          onValueChange={setStartDateOfEmploymentUndecidedOther}
          onErrorChange={setStartDateOfEmploymentUndecidedOtherError}
          onBlur={() => {
            saveFormN({ "start_date_of_employment_if_it_is_undecided_other": startDateOfEmploymentUndecidedOther });
          }}
        />
      )}

      {/* Monthly compensation before tax (Yen) */}
      <TextField
        required
        placeholder='300000'
        label={t('monthlyCompensationBeforeTaxYen')}
        value={monthlyCompensionBeforeTaxYen}
        error={monthlyCompensionBeforeTaxYenError || getEmptyError(monthlyCompensionBeforeTaxYen)}
        restriction={tWithoutPrefix('inputNote.halfwidthNumber')}
        note={t('noteOnMonthlyCompensationBeforeTaxYen')}
        maxLength={14}
        validators={[validation.isNumeric, validation.createLengthValidator(14)]}
        onValueChange={setMonthlyCompensionBeforeTaxYen}
        onErrorChange={setMonthlyCompensionBeforeTaxYenError}
        onBlur={() => {
          saveFormN({ "salary_reward": monthlyCompensionBeforeTaxYen });
        }}
      />

      {/* Years of business experience */}
      <TextField
        required
        label={t('yearsOfBusinessExperience')}
        placeholder="10"
        value={yearsOfBusinessExperience}
        error={yearsOfBusinessExperienceError || getEmptyError(yearsOfBusinessExperience)}
        restriction={tWithoutPrefix('inputNote.halfwidthNumber')}
        maxLength={2}
        validators={[validation.isNumeric, validation.createLengthValidator(2)]}
        onValueChange={setYearsOfBusinessExperience}
        onErrorChange={setYearsOfBusinessExperienceError}
        onBlur={() => {
          saveFormN({ "business_experience": yearsOfBusinessExperience });
        }}
      />

      {/* Does the applicant have a position/title? */}
      <RadioField
        required
        label={t('doesApplicantHavePositionTitle')}
        options={[
          {
            label: t('yes'),
            value: DoesApplicantHavePositionOrTitle.Yes,
          },
          {
            label: t('no'),
            value: DoesApplicantHavePositionOrTitle.No,
          },
        ]}
        value={doesApplicantHavePositionTitle}
        error={getEmptyError(doesApplicantHavePositionTitle)}
        onValueChange={val => {
          let data: Partial<FormN> = {};
          data["position"] = val;

          if (val === DoesApplicantHavePositionOrTitle.No) {
            setNameOfPositionTitle('');
            setNameOfPositionTitleError('');
            data["position_name"] = '';
          }

          setDoesApplicantHavePositionTitle(val);
          saveFormN(data);
        }}
      />

      {/* Name of position/title */}
      { doesApplicantHavePositionTitle === DoesApplicantHavePositionOrTitle.Yes && (
        <TextField
          required
          label={t('nameOfPositionTitle')}
          placeholder="課長"
          value={nameOfPositionTitle}
          error={nameOfPositionTitleError}
          maxLength={172}
          validators={[validation.createLengthValidator(172)]}
          onValueChange={setNameOfPositionTitle}
          onErrorChange={setNameOfPositionTitleError}
          onBlur={() => {
            saveFormN({ "position_name": nameOfPositionTitle });
          }}
        />
      )}

      {/* Main occupation/type of work */}
      <SelectField
        required
        label={t('mainOccupationTypeOfWork')}
        placeholder={tWithoutPrefix('placeholder.select')}
        options={TYPE_OF_WORK_OPTIONS}
        value={mainOccupationTypeOfWork}
        error={getEmptyError(mainOccupationTypeOfWork)}
        onValueChange={val => {
          setMainOccupationTypeOfWork(val);
          saveFormN({ "occupation_main_type_of_work": val });
        }}
      />

      {/* Other occupation 1 */}
      <SelectField
        label={t('otherOccupation1')}
        placeholder={tWithoutPrefix('placeholder.select')}
        options={TYPE_OF_WORK_OPTIONS}
        value={otherOccupation1}
        error={getEmptyError(otherOccupation1)}
        onValueChange={val => {
          setOtherOccupation1(val);
          saveFormN({ "occupation_other_occupation_1": val });
        }}
      />

      {/* Other occupation 2 */}
      <SelectField
        label={t('otherOccupation2')}
        placeholder={tWithoutPrefix('placeholder.select')}
        options={TYPE_OF_WORK_OPTIONS}
        value={otherOccupation2}
        error={getEmptyError(otherOccupation2)}
        onValueChange={val => {
          setOtherOccupation2(val);
          saveFormN({ "occupation_other_occupation_2": val });
        }}
      />

      {/* Details of applicant's work */}
      <MultiTextField
        required
        label={t('detailsOfApplicantsWork')}
        value={detailsOfApplicantsWork}
        error={detailsOfApplicantsWorkError || getEmptyError(detailsOfApplicantsWork)}
        maxLength={600}
        validators={[validation.createLengthValidator(600)]}
        onValueChange={setDetailsOfApplicantsWork}
        onErrorChange={setDetailsOfApplicantsWorkError }
        onBlur={() => {
          saveFormN({ "details_of_activities": detailsOfApplicantsWork });
        }}
      />
      
    </Section>
  );
};

export default EmploymentConditionsSection;
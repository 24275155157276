
export type SupplementaryInfo = {
  id: string;
  created_at: string;
  updated_at: string;

  visa_application_id: string;

  appropriate_organization_description: string | null;
  first_renewal_application: string | null;
  start_date_of_employment_kind: string | null;
}

//Used for "appropriate_organization_description"
export enum OrganizationType {
  OptionA = "OptionA",
  OptionB = "OptionB",
  OptionC = "OptionC",
  OptionD = "OptionD",
}

//Used for "is_first_renewal_application" indirectly
export enum FirstRenewalApplication {
  Yes = "Yes",
  No = "No",
}
export enum VisaAttachmentKind {
  Application = 'application',
  Photo = 'photo',
  Passport = 'passport',
  ResidenceCard = 'residence_card',
  //The following item name says "Supporter" but the string says "dependent". This difference is intentional.
  //Backend uses the official translation and it uses the word "dependent", actually meaning "supporter". 
  //Most likely, it is a mistranslation
  DocsProvingRelationshipBtwApplicantAndSupporter = 'documents_proving_the_relationship_between_the_applicant_and_the_dependent',
  SupporterResidenceCardOrPassport = 'supporter_residence_card_or_passport',
  DocsCertifyingOccupationAndIncomeOfDependent = 'documents_certifying_occupation_and_income_of_dependent',

  DocsProvingCategory = 'documents_proving_category',
  MaterialsThatClarifyTheDetailsOfTheApplicantsActivitiesEtc = 'materials_that_clarify_the_details_of_the_applicants_activities_etc',
  RegistrationCertificate = 'registration_certificate',
  MaterialsThatClarifyTheBusinessDetails = 'materials_that_clarify_the_business_details',
  FinancialStatements = 'financial_statements',
  ResidentTaxTaxationCertificateAndTaxPaymentCertificate = 'resident_tax_taxation_certificate_and_tax_payment_certificate',
  MaterialsThatClarifyTheReason = 'materials_that_clarify_the_reason',

  Other = 'other'
}

export type VisaAttachment = {
  id: number;
  created_at: string;
  updated_at: string;

  visa_application_id: number;
  kind: VisaAttachmentKind;
  url: string;
  size: number;
}
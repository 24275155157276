//The label numbers come from the spreadsheet:
//See: https://docs.google.com/spreadsheets/d/1DSH9psUEakHitz56tuTrRxzV0y07nI_KhafCmHlUjCI/edit#gid=1606825711

export const FINAL_EDUCATION_OPTIONS_FOR_TECHNICAL = [
  {
      "label": "finalEducationForTechnical.1",
      "value": "大学院(博士)"
  },
  {
      "label": "finalEducationForTechnical.2",
      "value": "大学院(修士)"
  },
  {
      "label": "finalEducationForTechnical.3",
      "value": "大学"
  },
  {
      "label": "finalEducationForTechnical.4",
      "value": "短期大学"
  },
  {
      "label": "finalEducationForTechnical.5",
      "value": "専門学校"
  },
  {
      "label": "finalEducationForTechnical.6",
      "value": "高等学校"
  },
  {
      "label": "finalEducationForTechnical.7",
      "value": "中学校"
  },
  {
      "label": "finalEducationForTechnical.9",
      "value": "その他"
  }
];

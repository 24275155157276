import { FunctionComponent } from 'react';
import styled from 'styled-components';

import ErrorMessage from '../display/ErrorMessage';
import Restriction from '../display/Restriction';
import InlineMessage from '../form/InlineMessage';
import Label from '../form/Label';
import Select from '../form/Select';
import { ValidationFunction } from '../../types/validation';
import { SelectOption } from '../../types/option';

interface SelectFieldProps {
    disabled?: boolean;
    required?: boolean;
    label?: string;
    placeholder?: string;
    options?: SelectOption[];
    value?: string;
    error?: string;
    restriction?: string;
    note?: string;
    validators?: ValidationFunction[];
    onValueChange?: (value: any) => void;
    onErrorChange?: (error: string) => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const SelectField: FunctionComponent<SelectFieldProps> = ({
    disabled,
    required,
    label,
    placeholder,
    options,
    value,
    error,
    restriction,
    note,
    validators,
    onValueChange,
    onErrorChange,
}) => {
    const handleChange = (value: any) => {
        onValueChange?.(value);
        if (validators) {
            for (const validator of validators) {
                const result = validator(value);
                if (!result.success) {
                    onErrorChange?.(result.message);
                    return;
                }
            }
        }
    };

    return (
        <Container>
            {label && (
                <Label required={required}>
                    {label}
                </Label>
            )}
            <Select
                searchable
                disabled={disabled}
                invalid={!!error}
                placeholder={placeholder}
                options={options}
                value={value}
                onValueChange={handleChange}
            />
            {error && (
                <ErrorMessage>
                    {error}
                </ErrorMessage>
            )}
            {restriction && (
                <Restriction content={restriction} />
            )}
            {note && (
                <InlineMessage variant='info' size="small">
                    {note}
                </InlineMessage>
            )}
        </Container>
    );
};

export default SelectField;
import { FunctionComponent, useEffect, useState } from 'react';
import { VisaFormProps } from '../../../types/visa/formProp';
import Accordion from '../../../components/navigation/Accordion';
import BasicInformationSection from '../ApplicantBlock/BasicInformationSection';
import StatusOfResidenceSection from '../ApplicantBlock/StatusOfResidenceSection';
import { useTranslation } from 'react-i18next';
import DetailsOfExtensionSection from '../ApplicantBlock/DetailsOfExtensionSection';
import FamilyCoresidentsSection from '../ApplicantBlock/FamilyCoresidentsSection';
import OtherSection from '../ApplicantBlock/OtherSection';
import { ReactComponent as ClipIcon } from '../../../assets/icon-clip.svg';
import styled from 'styled-components';
import Text from '../../../components/text/Text';
import Column from '../../../components/layout/Column';
import { FormN } from '../../../types/uncommonFormParts/formN/data';
import OrganizationSection from '../OrganizationBlock/OrganizationSection';
import EmploymentConditionsSection from '../OrganizationBlock/EmploymentConditionsSection';
import AttachmentsSection from '../SharedBlock/AttachmentSection/AttachmentsSection';
import { SupplementaryInfo } from '../../../types/uncommonFormParts/supplementaryInfo';
import { isThisVisaChange, isThisVisaExtension } from '../../../utils/visaApplicationHelper';
import PlaceOfEmploymentSection from '../ApplicantBlock/PlaceOfEmploymentSection';
import FinalEducationSection from '../ApplicantBlock/FinalEducationSection';
import WorkHistorySection from '../ApplicantBlock/WorkHistorySection';

interface TechnicalVisaFormProps extends VisaFormProps {
  formN: FormN | null;
  supplementaryInfo: SupplementaryInfo | null;
  onChangeFormN: (data: Partial<FormN>) => void;
  onChangeSupplementaryInfo: (data: Partial<SupplementaryInfo>) => void;
  onVisaAttachmentsDelete: (visaAttachmentIds: number []) => void;
} 

const ContentWrapper = styled(Column)`
  gap: 40px;
`;

const BlockWrapper = styled.div`
  width: 100%;
`;

const BlockHeadingWrapper = styled.div`
  padding: 10px 20px 20px 20px;
`;

const BlockHeading = styled(Text)`
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
`;

const TechnicalVisaForm: FunctionComponent<TechnicalVisaFormProps>  = ({
  visaApplicationType,
  extensionBySelfData,
  changeBySelfData,
  formN,
  supplementaryInfo,
  visaAttachments,
  onExtensionBySelfDataChange,
  onChangeBySelfDataChange,
  onChangeFormN,
  onChangeSupplementaryInfo,
  onApplicationReadinessChange,
  onVisaAttachmentUpload,
  onVisaAttachmentDelete,
  onVisaAttachmentsDelete,
  showErrorAlerts
}) => {
  const { t } = useTranslation();
  const isVisaExtension = isThisVisaExtension(visaApplicationType);
  const isVisaChange = isThisVisaChange(visaApplicationType);
  const isMissingSomething = (vals: any []) => vals.some(val => !!!val);
  const isDataInsufficient = 
    (isVisaExtension && isMissingSomething([extensionBySelfData, formN, supplementaryInfo])) || 
    (isVisaChange && isMissingSomething([changeBySelfData, formN, supplementaryInfo]));
  const [activeSection, setActiveSection] = useState<string>('');
  const toggleActiveSection = (key: string) => setActiveSection(activeSection === key ? '' : key);

  const [isBasicInfoSecReady, setIsBasicInfoSecReady] = useState<boolean>(false);
  const [isStatusOfResidenceSecReady, setIsStatusOfResidenceSecReady] = useState<boolean>(false);
  const [isDetailsOfExtensionSecReady, setIsDetailsOfExtensionSecReady] = useState<boolean>(false);
  const [isFamilyCohabitantsSecReady, setIsFamilyCohabitantsSecReady] = useState<boolean>(false);
  const [isPlaceOfEmploymentSecReady, setIsPlaceOfEmploymentSecReady] = useState<boolean>(false);
  const [isFinalEducationSecReady, setIsFinalEducationSecSecReady] = useState<boolean>(false);
  const [isOtherSecReady, setIsOtherSecReady] = useState<boolean>(false);
  const [isOrganizationSecReady, setIsOrganizationSecReady] = useState<boolean>(false);
  const [isWorkHistorySecReady, setIsWorkHistorySecReady] = useState<boolean>(false);
  const [isEmploymentConditionsSecReady, setIsEmploymentConditionsSecReady] = useState<boolean>(false);
  const [isAttachmentsSecReaedy, setIsAttachmentsSecReady] = useState<boolean>(false);


  useEffect (() => {
    onApplicationReadinessChange([
      isBasicInfoSecReady,
      isStatusOfResidenceSecReady,
      isDetailsOfExtensionSecReady,
      isFamilyCohabitantsSecReady,
      isPlaceOfEmploymentSecReady,
      isFinalEducationSecReady,
      isWorkHistorySecReady,
      isOtherSecReady,
      isOrganizationSecReady,
      isEmploymentConditionsSecReady,
      isAttachmentsSecReaedy
    ].every(isSectionReady => isSectionReady));
  }, [
    isBasicInfoSecReady,
    isStatusOfResidenceSecReady,
    isDetailsOfExtensionSecReady,
    isFamilyCohabitantsSecReady,
    isPlaceOfEmploymentSecReady,
    isFinalEducationSecReady,
    isWorkHistorySecReady,
    isOtherSecReady,
    isOrganizationSecReady,
    isEmploymentConditionsSecReady,
    isAttachmentsSecReaedy
  ])
  
  if (isDataInsufficient){
    return null;
  }
    

  return (
    <ContentWrapper>

      {/* Application block */}
      <BlockWrapper>

        <BlockHeadingWrapper>
          <BlockHeading>{t("applicationFormPage.blocks.forApplicant")}</BlockHeading>
        </BlockHeadingWrapper>

        <div>
          {/* Basic Information */}
          <Accordion
            active={activeSection === 'basic'}
            label={t("basicInformationSection.title")}
            onClick={() => toggleActiveSection('basic')}
            isError={showErrorAlerts && !isBasicInfoSecReady}
          >
            <BasicInformationSection
              visaApplicationType={visaApplicationType}
              extensionBySelfData={extensionBySelfData}
              changeBySelfData={changeBySelfData}
              onChangeExtensionBySelfData={onExtensionBySelfDataChange}
              onChangeChangeBySelfData={onChangeBySelfDataChange}
              onSectionReadinessChange={setIsBasicInfoSecReady}
              isEmptyRequiredValueError={showErrorAlerts}
            />
          </Accordion>
          

          {/* Status of Residence */}
          <Accordion
            active={activeSection === 'residence'}
            label={t("statusOfResidenceSection.title")}
            onClick={() => toggleActiveSection('residence')}
            isError={showErrorAlerts && !isStatusOfResidenceSecReady}
          >
            <StatusOfResidenceSection
              visaApplicationType={visaApplicationType}
              extensionBySelfData={extensionBySelfData}
              changeBySelfData={changeBySelfData}
              onChangeExtensionBySelfData={onExtensionBySelfDataChange}
              onChangeChangeBySelfData={onChangeBySelfDataChange}
              onSectionReadinessChange={setIsStatusOfResidenceSecReady}
              isEmptyRequiredValueError={showErrorAlerts}
            />
          </Accordion>
        

          {/* Details of Extension */}
          {/* TODO: Render conditionally when Visa Change for Family visa is added */}
          <Accordion
            active={activeSection === 'details'}
            label={t("detailsOfExtensionSection.title")}
            onClick={() => toggleActiveSection('details')}
            isError={showErrorAlerts && !isDetailsOfExtensionSecReady}
          >
            <DetailsOfExtensionSection
              visaApplicationType={visaApplicationType}
              extensionBySelfData={extensionBySelfData}
              onChangeExtensionBySelfData={onExtensionBySelfDataChange}
              onSectionReadinessChange={setIsDetailsOfExtensionSecReady}
              isEmptyRequiredValueError={showErrorAlerts}
            />
          </Accordion>


          {/* Family/Co-residents */}
          <Accordion
            active={activeSection === 'family-coresidents'}
            label={t("familyCoresidentsSection.title")}
            onClick={() => toggleActiveSection('family-coresidents')}   
            isError={showErrorAlerts && !isFamilyCohabitantsSecReady}
          >
            <FamilyCoresidentsSection
              visaApplicationType={visaApplicationType}
              extensionBySelfData={extensionBySelfData}
              changeBySelfData={changeBySelfData}
              onChangeExtensionBySelfData={onExtensionBySelfDataChange}
              onChangeChangeBySelfData={onChangeBySelfDataChange}
              onSectionReadinessChange={setIsFamilyCohabitantsSecReady}
              isEmptyRequiredValueError={showErrorAlerts}
            />
          </Accordion>


          {/* Place of Employment */}
          <Accordion
            active={activeSection === 'place-of-employment'}
            label={t('placeOfEmploymentSection.title')}
            onClick={() => toggleActiveSection('place-of-employment')}   
            isError={showErrorAlerts && !isPlaceOfEmploymentSecReady}
          >
            <PlaceOfEmploymentSection
              visaApplicationType={visaApplicationType}
              formN={formN!}
              onChangeFormN={onChangeFormN}
              onSectionReadinessChange={setIsPlaceOfEmploymentSecReady}
              isEmptyRequiredValueError={showErrorAlerts}
            />
          </Accordion>


          {/* Finaly Education */}
          <Accordion
            active={activeSection === 'final-education'}
            label={t('finalEducationSection.title')}
            onClick={() => toggleActiveSection('final-education')}   
            isError={showErrorAlerts && !isFinalEducationSecReady}
          >
            <FinalEducationSection
              visaApplicationType={visaApplicationType}
              formN={formN!}
              onChangeFormN={onChangeFormN}
              onSectionReadinessChange={setIsFinalEducationSecSecReady}
              isEmptyRequiredValueError={showErrorAlerts}
            />
          </Accordion>


          {/* Work History */}
          <Accordion
            active={activeSection === 'work-history'}
            label={t('workHistorySection.title')}
            onClick={() => toggleActiveSection('work-history')}   
            isError={showErrorAlerts && !isWorkHistorySecReady}
          >
            <WorkHistorySection
              visaApplicationType={visaApplicationType}
              formN={formN!}
              onChangeFormN={onChangeFormN}
              onSectionReadinessChange={setIsWorkHistorySecReady}
              isEmptyRequiredValueError={showErrorAlerts}
            />
          </Accordion>


          {/* Other */}
          <Accordion
            active={activeSection === 'other'}
            label={t("otherSection.title")}
            onClick={() => toggleActiveSection('other')}
            isError={showErrorAlerts && !isOtherSecReady}
          >
              <OtherSection
                visaApplicationType={visaApplicationType}
                extensionBySelfData={extensionBySelfData}
                changeBySelfData={changeBySelfData}
                onChangeExtensionBySelfData={onExtensionBySelfDataChange}
                onChangeChangeBySelfData={onChangeBySelfDataChange}
                onSectionReadinessChange={setIsOtherSecReady}
                isEmptyRequiredValueError={showErrorAlerts}
              />
          </Accordion>
        </div>

      </BlockWrapper>


      {/* Organization block */}
      <BlockWrapper>

        <BlockHeadingWrapper>
          <BlockHeading>{t("applicationFormPage.blocks.forOrganization")}</BlockHeading>
        </BlockHeadingWrapper>
        
        <div>
          {/* Organization */}
          <Accordion
            active={activeSection === 'organization'}
            label={t("organizationSection.title")}
            onClick={() => toggleActiveSection('organization')}
            isError={ showErrorAlerts && !isOrganizationSecReady }
          >
            <OrganizationSection
              visaApplicationType={visaApplicationType}
              formN={formN}
              onChangeFormN={onChangeFormN}
              onSectionReadinessChange={setIsOrganizationSecReady}
              isEmptyRequiredValueError={showErrorAlerts}
            />          
          </Accordion>

          {/* Employment Conditions */}
          <Accordion
            active={activeSection === 'employment-conditions'}
            label={t("employmentConditionsSection.title")}
            onClick={() => toggleActiveSection('employment-conditions')}
            isError={ showErrorAlerts && !isEmploymentConditionsSecReady }
          >
            <EmploymentConditionsSection
              visaApplicationType={visaApplicationType}
              formN={formN}
              supplementaryInfo={supplementaryInfo}
              onChangeFormN={onChangeFormN}
              onChangeSupplementaryInfo={onChangeSupplementaryInfo}
              onSectionReadinessChange={setIsEmploymentConditionsSecReady}
              isEmptyRequiredValueError={showErrorAlerts}
            />          
          </Accordion>
        </div>

      </BlockWrapper>


      {/* Attachments block */}
      <BlockWrapper>

        <BlockHeadingWrapper>
          <BlockHeading>{t("applicationFormPage.blocks.attachments")}</BlockHeading>
        </BlockHeadingWrapper>

        <div>
          {/* Attachments */}
          <Accordion
            active={activeSection === 'attachment'}
            label={t("attachmentsSection.title")}
            onClick={() => toggleActiveSection('attachment')}
            iconComponent={<ClipIcon />}
            isError={ showErrorAlerts && !isAttachmentsSecReaedy }
          >
              <AttachmentsSection 
                visaApplicationType={visaApplicationType}
                visaAttachments={visaAttachments}
                supplementaryInfo={supplementaryInfo!}
                onVisaAttachmentUpload={onVisaAttachmentUpload}
                onVisaAttachmentDelete={onVisaAttachmentDelete}
                onVisaAttachmentsDelete={onVisaAttachmentsDelete}
                onChangeSupplementaryInfo={onChangeSupplementaryInfo}
                onSectionReadinessChange={setIsAttachmentsSecReady}
                showRequiredAlert={showErrorAlerts}
              />
          </Accordion>
        </div>

      </BlockWrapper>

    </ContentWrapper>
  )
}

export default TechnicalVisaForm;
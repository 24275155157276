//The label numbers come from the spreadsheet:
//See: https://docs.google.com/spreadsheets/d/1DSH9psUEakHitz56tuTrRxzV0y07nI_KhafCmHlUjCI/edit#gid=1606825711

export const MAJOR_FIELD_OF_STUDY_FOR_DRMSBCJC_OPTIONS_FOR_TECHNICAL = [
  {
      "label": "majorFieldOfStudyForDrMsBcJcForTechnical.101",
      "value": "法学"
  },
  {
      "label": "majorFieldOfStudyForDrMsBcJcForTechnical.102",
      "value": "経済学"
  },
  {
      "label": "majorFieldOfStudyForDrMsBcJcForTechnical.103",
      "value": "政治学"
  },
  {
      "label": "majorFieldOfStudyForDrMsBcJcForTechnical.104",
      "value": "商学"
  },
  {
      "label": "majorFieldOfStudyForDrMsBcJcForTechnical.105",
      "value": "経営学"
  },
  {
      "label": "majorFieldOfStudyForDrMsBcJcForTechnical.106",
      "value": "文学"
  },
  {
      "label": "majorFieldOfStudyForDrMsBcJcForTechnical.107",
      "value": "語学"
  },
  {
      "label": "majorFieldOfStudyForDrMsBcJcForTechnical.108",
      "value": "社会学"
  },
  {
      "label": "majorFieldOfStudyForDrMsBcJcForTechnical.109",
      "value": "歴史学"
  },
  {
      "label": "majorFieldOfStudyForDrMsBcJcForTechnical.110",
      "value": "心理学"
  },
  {
      "label": "majorFieldOfStudyForDrMsBcJcForTechnical.111",
      "value": "教育学"
  },
  {
      "label": "majorFieldOfStudyForDrMsBcJcForTechnical.112",
      "value": "芸術学"
  },
  {
      "label": "majorFieldOfStudyForDrMsBcJcForTechnical.199",
      "value": "その他人文・社会科学"
  },
  {
      "label": "majorFieldOfStudyForDrMsBcJcForTechnical.201",
      "value": "理学"
  },
  {
      "label": "majorFieldOfStudyForDrMsBcJcForTechnical.202",
      "value": "化学"
  },
  {
      "label": "majorFieldOfStudyForDrMsBcJcForTechnical.203",
      "value": "工学"
  },
  {
      "label": "majorFieldOfStudyForDrMsBcJcForTechnical.204",
      "value": "農学"
  },
  {
      "label": "majorFieldOfStudyForDrMsBcJcForTechnical.205",
      "value": "水産学"
  },
  {
      "label": "majorFieldOfStudyForDrMsBcJcForTechnical.206",
      "value": "薬学"
  },
  {
      "label": "majorFieldOfStudyForDrMsBcJcForTechnical.207",
      "value": "医学"
  },
  {
      "label": "majorFieldOfStudyForDrMsBcJcForTechnical.208",
      "value": "歯学"
  },
  {
      "label": "majorFieldOfStudyForDrMsBcJcForTechnical.209",
      "value": "体育学"
  },
  {
      "label": "majorFieldOfStudyForDrMsBcJcForTechnical.210",
      "value": "介護福祉"
  },
  {
      "label": "majorFieldOfStudyForDrMsBcJcForTechnical.299",
      "value": "その他自然科学"
  },
  {
      "label": "majorFieldOfStudyForDrMsBcJcForTechnical.999",
      "value": "その他"
  }
];


export const MAJOR_FIELD_OF_STUDY_FOR_COLLEGE_OF_TECHNOLOGY_OPTIONS_FOR_TECHNICAL = [
  {
      "label": "majorFieldOfStudyForCollegeOfTechnologyForTechnical.01",
      "value": "工業"
  },
  {
      "label": "majorFieldOfStudyForCollegeOfTechnologyForTechnical.02",
      "value": "農業"
  },
  {
      "label": "majorFieldOfStudyForCollegeOfTechnologyForTechnical.03",
      "value": "医療・衛生"
  },
  {
      "label": "majorFieldOfStudyForCollegeOfTechnologyForTechnical.05",
      "value": "教育・社会福祉"
  },
  {
      "label": "majorFieldOfStudyForCollegeOfTechnologyForTechnical.04",
      "value": "法律"
  },
  {
      "label": "majorFieldOfStudyForCollegeOfTechnologyForTechnical.06",
      "value": "商業実務"
  },
  {
      "label": "majorFieldOfStudyForCollegeOfTechnologyForTechnical.07",
      "value": "服飾・家政"
  },
  {
      "label": "majorFieldOfStudyForCollegeOfTechnologyForTechnical.08",
      "value": "文化・教養"
  },
  {
      "label": "majorFieldOfStudyForCollegeOfTechnologyForTechnical.09",
      "value": "介護福祉"
  },
  {
      "label": "majorFieldOfStudyForCollegeOfTechnologyForTechnical.99",
      "value": "その他"
  }
];

import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { VisaApplicationType } from "../../../../../../../types/visa/applicationType";
import { VisaAttachment } from "../../../../../../../types/visa/attachment";
import FamilyVisaAttachments from "./SectionBodies/FamilyVisaAttachments";
import { PreviewHeading } from "../../../previewBodyCommonStyle";
import { VisaApplication } from "../../../../../../../types/visa/applicationData";

interface AttachmentsSectionProps {
  visaApplication: VisaApplication;
  visaApplicationType: VisaApplicationType;
  visaAttachments: VisaAttachment[];
}

export const AttachmentsSection: FunctionComponent<AttachmentsSectionProps> = ({
  visaApplication,
  visaApplicationType,
  visaAttachments,
}) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "attachmentsSection",
  });
  const Attachments = (() => {
    switch (visaApplicationType) {
      case VisaApplicationType.ExtendFamilyVisaBySelf:
        return (
          <FamilyVisaAttachments
            visaApplication={visaApplication}
            visaAttachments={visaAttachments}
            visaApplicationType={visaApplicationType}
          />
        );

      default:
        return null;
    }
  })();

  return (
    <section>
      <PreviewHeading>{t("title")}</PreviewHeading>
      {Attachments}
    </section>
  );
};

export default AttachmentsSection;

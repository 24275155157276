import { Navigate, Route, Routes } from "react-router-dom";
import { useDispatcher } from "./hooks";
import ApplicationFormPage from "./pages/ApplicationFormPage";
import AccountPage from "./pages/AccountPage";
import Onboarding from "./pages/OnboardingPage";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import SignupPage from "./pages/SignupPage";
import { useEffect } from "react";
import { getCurrentUser } from "./apis/user";
import ApplicationReviewPage from "./pages/ApplicationReviewPage/ApplicationReviewPage";
import { extractUserRegistrationAppStatus, isUserRegistrationApplicationDenied, isUserRegistrationApplicationDraft } from "./utils/userRegistrationHelper";
import { getLatestUserRegistrationApplication } from "./apis/userRegistration";
import SetupPage from "./pages/SetupPage";
import { isSharedPageAccess } from "./utils/invitationRelatedHelper";
import InvalidLinkPage from "./pages/InvalidLinkPage";

const SiteRoutes = () => {
  const { state, dispatcher } = useDispatcher();
  const { isLoggedIn, isSetupComplete, userRegistrationAppStatus } = state;
  const isUserRegAppDenied = isUserRegistrationApplicationDenied(userRegistrationAppStatus);
  const canSkipOnboarding = process.env.REACT_APP_CAN_SKIP_ONBOARDING === 'true'; 
  const isUserRegAppRequiredToCompleteFirst = 
    isUserRegistrationApplicationDraft(userRegistrationAppStatus) &&
    !canSkipOnboarding;
  const isAccessingSharedPage = isSharedPageAccess();


  useEffect(() => {
    (async () => {
      try {
        //For the invited user accessing the shared page, 
        //we don't need to check the user's login status
        if (isAccessingSharedPage) 
          return;
        
        //Check if the user is really logged in or not
        const user = await getCurrentUser();
        
        if (user) 
          dispatcher.treatAsLoggedIn();
        else {
          dispatcher.treatAsLoggedOut();
          return;
        }

        const userRegistrationApplication = await getLatestUserRegistrationApplication();
        const latestAppStatus = extractUserRegistrationAppStatus(userRegistrationApplication);

        if (latestAppStatus !== state.userRegistrationAppStatus)
          dispatcher.setUserRegistrationAppStatus(latestAppStatus);

        if (user.status_of_residence && user.user_setting.language)
          dispatcher.completeSetup();

      } catch (e) {
        dispatcher.treatAsLoggedOut();  
      }
    })();
  }, []);

  if (isAccessingSharedPage) 
    return (
      <Routes>
        <Route path="/shared/application/:id/edit" element={<ApplicationFormPage />} />
        <Route path="/shared/application/:id/review" element={<ApplicationReviewPage />} />
        <Route path="/shared/invalid" element={<InvalidLinkPage />} />
        <Route path="*" element={<Navigate replace to="/shared/invalid" />} />
      </Routes>
    )

  if (!isLoggedIn)
    return (
      <Routes>
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/shared/invalid" element={<InvalidLinkPage />} />
        <Route path="*" element={<Navigate replace to="/login" />} />
      </Routes>
    );

  if (!isSetupComplete)
    return (
      <Routes>
        <Route path="/setup" element={<SetupPage />} />
        <Route path="*" element={<Navigate replace to="/setup" />} />
      </Routes>
    );

  if (isUserRegAppRequiredToCompleteFirst)
    return (
      <Routes>
        <Route path="/onboarding" element={<Onboarding />} />
        <Route path="*" element={<Navigate replace to="/onboarding"/>} />
      </Routes>
    );

  return (
    <Routes>
      {(canSkipOnboarding || isUserRegAppDenied) && 
        <Route path="/onboarding" element={<Onboarding />} />
      }
      <Route path="/application/:id/review" element={<ApplicationReviewPage />} />
      <Route path="/application/:id/edit" element={<ApplicationFormPage />} />
      <Route path="/account" element={<AccountPage />} />
      <Route path="/" element={<HomePage />} />
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  )
};

export default SiteRoutes;

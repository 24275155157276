import { CurrentStatusOfResidenceCode } from "../../types/visa/currentStatusOfResidence";

export const CURRENT_STATUS_OF_RESIDENCE_OPTIONS = [
    {
        "label": "currentStatusOfResidence.dependent",
        "value": CurrentStatusOfResidenceCode.Dependent
    },
    {
        "label": "currentStatusOfResidence.technical",
        "value": CurrentStatusOfResidenceCode.EngineerOrSpecialistInHumanitiesOrInternationalServices
    },
    {
        "label": "currentStatusOfResidence.spouseOrChildOfPermanentResident",
        "value": CurrentStatusOfResidenceCode.SpouseOrChildOfPermanentResident
    },
    {
        "label": "currentStatusOfResidence.longTermResident",
        "value": CurrentStatusOfResidenceCode.LongTermResident
    }
];


export const PREFECTURE_OPTIONS = [
  {
      "label": "prefecture.hokkaido",
      "value": "01"
  },
  {
      "label": "prefecture.aomori",
      "value": "02"
  },
  {
      "label": "prefecture.iwate",
      "value": "03"
  },
  {
      "label": "prefecture.miyagi",
      "value": "04"
  },
  {
      "label": "prefecture.akita",
      "value": "05"
  },
  {
      "label": "prefecture.yamagata",
      "value": "06"
  },
  {
      "label": "prefecture.fukushima",
      "value": "07"
  },
  {
      "label": "prefecture.ibaraki",
      "value": "08"
  },
  {
      "label": "prefecture.tochigi",
      "value": "09"
  },
  {
      "label": "prefecture.gunma",
      "value": "10"
  },
  {
      "label": "prefecture.saitama",
      "value": "11"
  },
  {
      "label": "prefecture.chiba",
      "value": "12"
  },
  {
      "label": "prefecture.tokyo",
      "value": "13"
  },
  {
      "label": "prefecture.kanagawa",
      "value": "14"
  },
  {
      "label": "prefecture.niigata",
      "value": "15"
  },
  {
      "label": "prefecture.toyama",
      "value": "16"
  },
  {
      "label": "prefecture.ishikawa",
      "value": "17"
  },
  {
      "label": "prefecture.fukui",
      "value": "18"
  },
  {
      "label": "prefecture.yamanashi",
      "value": "19"
  },
  {
      "label": "prefecture.nagano",
      "value": "20"
  },
  {
      "label": "prefecture.gifu",
      "value": "21"
  },
  {
      "label": "prefecture.shizuoka",
      "value": "22"
  },
  {
      "label": "prefecture.aichi",
      "value": "23"
  },
  {
      "label": "prefecture.mie",
      "value": "24"
  },
  {
      "label": "prefecture.shiga",
      "value": "25"
  },
  {
      "label": "prefecture.kyoto",
      "value": "26"
  },
  {
      "label": "prefecture.osaka",
      "value": "27"
  },
  {
      "label": "prefecture.hyogo",
      "value": "28"
  },
  {
      "label": "prefecture.nara",
      "value": "29"
  },
  {
      "label": "prefecture.wakayama",
      "value": "30"
  },
  {
      "label": "prefecture.tottori",
      "value": "31"
  },
  {
      "label": "prefecture.shimane",
      "value": "32"
  },
  {
      "label": "prefecture.okayama",
      "value": "33"
  },
  {
      "label": "prefecture.hiroshima",
      "value": "34"
  },
  {
      "label": "prefecture.yamaguchi",
      "value": "35"
  },
  {
      "label": "prefecture.tokushima",
      "value": "36"
  },
  {
      "label": "prefecture.kagawa",
      "value": "37"
  },
  {
      "label": "prefecture.ehime",
      "value": "38"
  },
  {
      "label": "prefecture.kochi",
      "value": "39"
  },
  {
      "label": "prefecture.fukuoka",
      "value": "40"
  },
  {
      "label": "prefecture.saga",
      "value": "41"
  },
  {
      "label": "prefecture.nagasaki",
      "value": "42"
  },
  {
      "label": "prefecture.kumamoto",
      "value": "43"
  },
  {
      "label": "prefecture.oita",
      "value": "44"
  },
  {
      "label": "prefecture.miyazaki",
      "value": "45"
  },
  {
      "label": "prefecture.kagoshima",
      "value": "46"
  },
  {
      "label": "prefecture.okinawa",
      "value": "47"
  }
];


//Used for uncommon form parts because we shouldn't send the codes like "01" to the backend
export const PREFECTURE_WITH_JP_VALUE_OPTIONS = [
    {
        "label": "prefecture.hokkaido",
        "value": "北海道"
    },
    {
        "label": "prefecture.aomori",
        "value": "青森県"
    },
    {
        "label": "prefecture.iwate",
        "value": "岩手県"
    },
    {
        "label": "prefecture.miyagi",
        "value": "宮城県"
    },
    {
        "label": "prefecture.akita",
        "value": "秋田県"
    },
    {
        "label": "prefecture.yamagata",
        "value": "山形県"
    },
    {
        "label": "prefecture.fukushima",
        "value": "福島県"
    },
    {
        "label": "prefecture.ibaraki",
        "value": "茨城県"
    },
    {
        "label": "prefecture.tochigi",
        "value": "栃木県"
    },
    {
        "label": "prefecture.gunma",
        "value": "群馬県"
    },
    {
        "label": "prefecture.saitama",
        "value": "埼玉県"
    },
    {
        "label": "prefecture.chiba",
        "value": "千葉県"
    },
    {
        "label": "prefecture.tokyo",
        "value": "東京都"
    },
    {
        "label": "prefecture.kanagawa",
        "value": "神奈川県"
    },
    {
        "label": "prefecture.niigata",
        "value": "新潟県"
    },
    {
        "label": "prefecture.toyama",
        "value": "富山県"
    },
    {
        "label": "prefecture.ishikawa",
        "value": "石川県"
    },
    {
        "label": "prefecture.fukui",
        "value": "福井県"
    },
    {
        "label": "prefecture.yamanashi",
        "value": "山梨県"
    },
    {
        "label": "prefecture.nagano",
        "value": "長野県"
    },
    {
        "label": "prefecture.gifu",
        "value": "岐阜県"
    },
    {
        "label": "prefecture.shizuoka",
        "value": "静岡県"
    },
    {
        "label": "prefecture.aichi",
        "value": "愛知県"
    },
    {
        "label": "prefecture.mie",
        "value": "三重県"
    },
    {
        "label": "prefecture.shiga",
        "value": "滋賀県"
    },
    {
        "label": "prefecture.kyoto",
        "value": "京都府"
    },
    {
        "label": "prefecture.osaka",
        "value": "大阪府"
    },
    {
        "label": "prefecture.hyogo",
        "value": "兵庫県"
    },
    {
        "label": "prefecture.nara",
        "value": "奈良県"
    },
    {
        "label": "prefecture.wakayama",
        "value": "和歌山県"
    },
    {
        "label": "prefecture.tottori",
        "value": "鳥取県"
    },
    {
        "label": "prefecture.shimane",
        "value": "島根県"
    },
    {
        "label": "prefecture.okayama",
        "value": "岡山県"
    },
    {
        "label": "prefecture.hiroshima",
        "value": "広島県"
    },
    {
        "label": "prefecture.yamaguchi",
        "value": "山口県"
    },
    {
        "label": "prefecture.tokushima",
        "value": "徳島県"
    },
    {
        "label": "prefecture.kagawa",
        "value": "香川県"
    },
    {
        "label": "prefecture.ehime",
        "value": "愛媛県"
    },
    {
        "label": "prefecture.kochi",
        "value": "高知県"
    },
    {
        "label": "prefecture.fukuoka",
        "value": "福岡県"
    },
    {
        "label": "prefecture.saga",
        "value": "佐賀県"
    },
    {
        "label": "prefecture.nagasaki",
        "value": "長崎県"
    },
    {
        "label": "prefecture.kumamoto",
        "value": "熊本県"
    },
    {
        "label": "prefecture.oita",
        "value": "大分県"
    },
    {
        "label": "prefecture.miyazaki",
        "value": "宮崎県"
    },
    {
        "label": "prefecture.kagoshima",
        "value": "鹿児島県"
    },
    {
        "label": "prefecture.okinawa",
        "value": "沖縄県"
    }
];
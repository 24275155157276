import { FunctionComponent, useEffect, useState } from "react";
import { ExtensionBySelfData } from "../../../../../../types/extensionBySelf/data";
import { ChangeBySelfData } from "../../../../../../types/changeBySelf/data";
import {
  getWorkHistoryLength,
  parseName,
} from "../../../../../../utils/visaFormDataHelper";
import { useTranslation } from "react-i18next";
import {
  Indent,
  PreviewHeading,
} from "../../previewBodyCommonStyle";
import { PreviewItem } from "../../../../../../types/modal/preview";
import { FormN } from "../../../../../../types/uncommonFormParts/formN/data";
import { formatDateString, generateRandomString } from "../../../../../../utils/utils";
import styled from "styled-components";
import Column from "../../../../../layout/Column";
import FieldGroupNumber from "../../../../../display/FieldGroupNumber";
import PreviewList from "../../../PreviewList";

interface WorkHistorySectionProps {
  data: FormN;
}

const LocalColumn = styled(Column)`
  margin-top: 20px;
  gap: 10px;
`;

export const WorkHistorySection: FunctionComponent<
  WorkHistorySectionProps
> = ({ data }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'workHistorySection' });
  const [previewItemsList, setPreviewItemsList] = useState<PreviewItem[][]>([]);

  useEffect(() => {
    const itemsList: PreviewItem[][] = [];

    for (let i = 1; i <= getWorkHistoryLength(data); i++) {
      const items: PreviewItem[] = [];

      const placeOfEmployment = data[`work_experience_${i}_place_of_employment` as keyof FormN] || "";
      const entryDate = data[`work_experience_${i}_date_of_joining` as keyof FormN] || "";
      const leaveDate = data[`work_experience_${i}_date_of_leaving` as keyof FormN] || "";

      items.push(
        {
          label: t("placeOfEmployment"),
          value: placeOfEmployment
        },
        {
          label: t("entryDate"),
          value: formatDateString(entryDate)
        },
        {
          label: t("leaveDate"),
          value: formatDateString(leaveDate)
        }
      );

      itemsList.push(items);
    }

    setPreviewItemsList(itemsList);
  }, [data]);

  return (
    <section>
      <PreviewHeading>{t("title")}</PreviewHeading>
      <Indent>
        {previewItemsList.map((items, i) => (
          <LocalColumn key={generateRandomString()}>
            <FieldGroupNumber>{i + 1}</FieldGroupNumber>
            <PreviewList items={items} />
          </LocalColumn>
        ))}
      </Indent>
    </section>
  );
};

export default WorkHistorySection;

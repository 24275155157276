import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { LocalColumn, PreviewBlockHeading } from "./previewBodyCommonStyle";
import { PreviewModalBodyProps } from "../../../../types/modal/preview";
import {
  isExtensionBySelfData,
  isThisVisaChange,
  isThisVisaExtension,
} from "../../../../utils/visaApplicationHelper";
import BasicInformationSection from "./previewBodySections/ApplicantBlock/BasicInformationSection";
import StatusOfResidenceSection from "./previewBodySections/ApplicantBlock/StatusOfResidenceSection";
import OtherSection from "./previewBodySections/ApplicantBlock/OtherSection";
import DetailsOfExtensionSection from "./previewBodySections/ApplicantBlock/DetailsOfExtensionSection";
import FamilyCoresidentsSection from "./previewBodySections/ApplicantBlock/FamilyCoresidentsSection";
import PlaceOfEmploymentSection from "./previewBodySections/ApplicantBlock/PlaceOfEmploymentSection";
import FinalEducationSection from "./previewBodySections/ApplicantBlock/FinalEducationSection";
import WorkHistorySection from "./previewBodySections/ApplicantBlock/WorkHistorySection";
import styled from "styled-components";
import OrganizationSection from "./previewBodySections/OrganizationBlock/OrganizationSection";
import EmploymentConditionsSection from "./previewBodySections/OrganizationBlock/EmploymentConditionsSection";
import AttachmentsSection from "./previewBodySections/SharedBlock/AttachmentsSection";

interface TechnicalVisaPreviewBodyProps extends PreviewModalBodyProps {}

const ApplicantBlock = styled(LocalColumn)`
`;

const OrganizationBlock = styled(LocalColumn)`
`;

const AttachmentBlock = styled(LocalColumn)`
`;

const TechnicalVisaPreviewBody: FunctionComponent<TechnicalVisaPreviewBodyProps> = ({
  visaApplication,
  visaApplicationType,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'modalPreview' });
  const isVisaExtension = isThisVisaExtension(visaApplicationType);
  const isVisaChange = isThisVisaChange(visaApplicationType);
  const commonData = isVisaExtension
    ? visaApplication.koushin_honnin
    : visaApplication.henkou_honnin;
  const uncommonData = visaApplication.form_n;

  if (!commonData || !uncommonData) return null;

  return (
    <LocalColumn>

      <ApplicantBlock>      
        <PreviewBlockHeading>{t("blockHeading.forApplicant")}</PreviewBlockHeading>
        <BasicInformationSection data={commonData} />

        <StatusOfResidenceSection
          visaApplicationType={visaApplicationType}
          data={commonData}
        />

        {isVisaExtension && isExtensionBySelfData(commonData) && (
          <DetailsOfExtensionSection
            data={commonData}
            visaApplicationType={visaApplicationType}
          />
        )}

        <FamilyCoresidentsSection data={commonData} />

        <PlaceOfEmploymentSection data={uncommonData} />
      
        <FinalEducationSection data={uncommonData} />

        <WorkHistorySection data={uncommonData} />

        <OtherSection 
          data={commonData} 
          visaApplicationType={visaApplicationType}
        />
      </ApplicantBlock>

      <OrganizationBlock>
        <PreviewBlockHeading>{t("blockHeading.forOrganization")}</PreviewBlockHeading>
        <OrganizationSection data={uncommonData} />
        <EmploymentConditionsSection 
          data={uncommonData} 
          supplementaryInfo={visaApplication.supplementary_info}
        />
      </OrganizationBlock>
      
      <AttachmentBlock>
        <PreviewBlockHeading>{t("blockHeading.attachments")}</PreviewBlockHeading>
        <AttachmentsSection 
          visaApplication={visaApplication}
          visaAttachments={visaApplication.visa_attachments} 
          visaApplicationType={visaApplicationType}
        />
      </AttachmentBlock>
    </LocalColumn>
  );
};

export default TechnicalVisaPreviewBody;

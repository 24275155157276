import { ChangeBySelfData } from "../changeBySelf/data";
import { ExtensionBySelfData } from "../extensionBySelf/data";
import { FormN } from "../uncommonFormParts/formN/data";
import { SupplementaryInfo } from "../uncommonFormParts/supplementaryInfo";
import { VisaApplicationStatus } from "./applicationStatus";
import { VisaAttachment } from "./attachment";
import { FormR } from "./uncommonFormParts/formR";

export enum CSVSourceKind {
  ExtensionBySelf = 'koushin_honnin',
  ChangeBySelf = 'henkou_honnin' 
}

export enum FormKind {
  R = 'r',
  N = 'n'
}

export type VisaApplication = {
  id: number;
  created_at: string;
  updated_at: string;
  
  user_id: number;
  visa_attachments: VisaAttachment[];
  
  last_submitted_visa_submission: VisaSubmission | null;
  last_unsubmitted_visa_submission: VisaSubmission | null;

  verify_to_submit: boolean; //when true, frontend has to verify the form data before submitting
  supplementary_info: SupplementaryInfo;
} & (
  {
    koushin_honnin: ExtensionBySelfData;
    henkou_honnin: null;
    csv_source_kind: CSVSourceKind.ExtensionBySelf;
  } | {
    koushin_honnin: null;
    henkou_honnin: ChangeBySelfData;
    csv_source_kind: CSVSourceKind.ChangeBySelf;
  } | {
    //This can happen when an empty VisaApplication is created
    koushin_honnin: null;
    henkou_honnin: null;
    csv_source_kind: null;
  }
) & (
  {
    form_kind: FormKind.N,
    form_n: FormN;
    form_r: null;
  } | {
    form_kind: FormKind.R;
    form_n: null;
    form_r: FormR;
  } | {
    form_kind: null;
    form_r: null;
    form_n: null;
  }
);

export type VisaSubmission = {
  id: number;
  created_at: string;
  updated_at: string;
  
  processed_at: string; 
  submitted_at: string;

  app_status: VisaApplicationStatus;
  visa_application_id: number;
  temporary_reference_number: string; //If this is not null, it means the submission should be done
  reference_number: string | null; //This field will be filled only after the user fetches the status of the application
  
  visa_messages: VisaMessage[];
}

export type VisaMessage = {
  id: number;
  created_at: string;
  updated_at: string;
  
  visa_submission_id: number;
  message_id: number;
  title: string;
  message_content: string;
  download_url: string;
  added_at: string;
}
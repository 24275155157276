//Keep KoushinHonnin (extensionBySelf) specific values here


//This value is not used in the form for the user. We directly assign the value to the field.
//This value must be used for this Koushin Honnin (extension by self) data 
//Ref: https://drive.google.com/file/d/1gDVnFJFv9KbmdKu7WYBTyZ3A0oVqEB1-/view
export const No = '1';

//This value is not used in the form for the user. We directly assign the value to the field.
//The original field is "WZAAS200Dto:chkHkeDui" (申請内容の控え有無)
//We only use Yes
export enum KeptVisaApplicationContent {
  Yes = "1",
  No = "0"
}

//Ref for the following codes:
//https://drive.google.com/file/d/1HbnLjpT5WG66zUp4OTHpBmjzhHRar7UF/view
//or
//https://docs.google.com/spreadsheets/d/1fdUHmF_UZyFnrnd901ad7u5_ocdaRnaZtedSM_65lyA/edit#gid=2000493674

//This is for selZiryskk (在留資格 / Status of residence)
//Don't use this for selCurrentZirySkk (現に有する在留資格 / Current status of residence)
export enum StatusOfResidenceCode {
  Family = "04R1",
  Technical = "04N2"
}

//This is for selSnsiShbt (申請種別 / Application category)
export enum ApplicationCategoryCode {
  Family = "04R",
  Technical = "04N"
}

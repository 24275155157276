import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "../../components/form/Button";
import RadioField from "../../components/compound/RadioField";
import { StatusOfResidenceOfUser } from "../../types/user/data";
import styled from "styled-components";
import { CONTENT_MAX_WIDTH } from "../../constants/styles";
import Page from "../../components/layout/Page";
import { updateCurrentUser } from "../../apis/user";
import { Language } from "../../types/language";
import { getLanguages } from "../../apis/language";
import { updateUserSetting } from "../../apis/userSetting";
import { useDispatcher } from "../../hooks";
import { useNavigate } from "react-router-dom";
import PageHeading from "../../components/text/PageHeading";

interface SetupPageProps {}

const LocalPage = styled(Page)`
  padding: 20px;
  gap: 30px;
`;

const ButtonContainer = styled.div`
  padding-top: 30px;

  @media (max-width: ${CONTENT_MAX_WIDTH}px) {
    flex-grow: 1;
    display: flex;
    flex-direction: column-reverse;
  }
`;

const SetupPage: FunctionComponent<SetupPageProps> = () => {
  const { dispatcher } = useDispatcher();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [statusOfResidence, setStatusOfResidence] = useState<StatusOfResidenceOfUser>();
  const [preferredLanguage, setPreferredLanguage] = useState("");

  const onClick = async () => {
    dispatcher.startLoading();
    try {
      const user = await updateCurrentUser({ status_of_residence: statusOfResidence,});
      const languages: Language[] = await getLanguages();
      await updateUserSetting(user.user_setting.id, {
        language_id: languages.find(
          (language) => language.code === preferredLanguage
        )?.id,
      });
      i18n.changeLanguage(preferredLanguage);
      localStorage.setItem("lng", preferredLanguage);
      dispatcher.completeSetup();
      setTimeout(() => navigate("/onboarding"), 0);
    } catch {
      dispatcher.showSnackbar(t("snackbar.failedToProceed"), "warning");
    } finally {
      dispatcher.stopLoading();
    }
  }

  return (
    <LocalPage>
      <PageHeading>{t("setupPage.setup")}</PageHeading>
      <RadioField
        label={t("setupPage.currentResidencyStatus")}
        options={[
          {
            label: t("setupPage.dependent"),
            value: StatusOfResidenceOfUser.Dependent,
          },
          {
            label: t("setupPage.technical"),
            value: StatusOfResidenceOfUser.Technical,
          },
        ]}
        defaultValue={statusOfResidence}
        value={statusOfResidence}
        onValueChange={setStatusOfResidence}
      />

      <RadioField
        label={t("setupPage.yourLanguage")}
        options={[
          {
            label: t("setupPage.english"),
            value: "en",
          },
          {
            label: t("setupPage.japanese"),
            value: "ja",
          },
        ]}
        value={preferredLanguage}
        onValueChange={setPreferredLanguage}
      />

      <ButtonContainer>
        <Button
          disabled={!statusOfResidence || !preferredLanguage}
          onClick={onClick}
        >
          {t("setupPage.next")}
        </Button>
      </ButtonContainer>
    </LocalPage>
  );
};

export default SetupPage;

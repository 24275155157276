import { PreviewAttachment, PreviewItem } from "../types/modal/preview";


export const isThisPreviewItem = (item: unknown): item is PreviewItem => {
  if (!item || typeof item !== 'object') return false

  const props = ['label', 'value'];
  return props.every(prop => prop in item)
}

export const isThisPreviewAttachment = (item: unknown): item is PreviewAttachment=> {
  if (!item || typeof item !== 'object') return false

  const props = ['label', 'files'];
  return props.every(prop => prop in item)
}
import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import FieldGroup from '../../../components/layout/FieldGroup';
import MultiTextField from '../../../components/compound/MultiTextField';
import RadioField from '../../../components/compound/RadioField';
import SelectField from '../../../components/compound/SelectField';
import TextField from '../../../components/compound/TextField';
import Section from '../../../components/layout/Section';
import {
    MUNICIPALITY_WITH_JP_VALUE_OPTIONS,
    PREFECTURE_WITH_JP_VALUE_OPTIONS
} from '../../../constants/options';
import { useValidation } from '../../../hooks';
import { VisaApplicationType } from '../../../types/visa/applicationType';
import { isThisVisaChange, isThisVisaExtension } from '../../../utils/visaApplicationHelper';
import { DoesWorkInvolveInInfoProcessing } from '../../../types/uncommonFormParts/formN/value';
import { FormN } from '../../../types/uncommonFormParts/formN/data';
import styled from 'styled-components';

interface PlaceOfEmploymentSectionProps {
  visaApplicationType: VisaApplicationType;
  formN: FormN;
  onChangeFormN: (data: Partial<FormN>) => void;
  onSectionReadinessChange: (isSectionReady: boolean) => void;
  isEmptyRequiredValueError: boolean;
};

const LocalFieldGroup = styled(FieldGroup)`
  background-color: #FFFFFF;
  border: solid 1px #D5D5D5;
`;

const PlaceOfEmploymentSection: FunctionComponent<PlaceOfEmploymentSectionProps> = ({
  visaApplicationType,
  formN,
  onChangeFormN,
  onSectionReadinessChange,
  isEmptyRequiredValueError,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'placeOfEmploymentSection' });
  const { t : tWithoutPrefix } = useTranslation();
  const validation = useValidation();
  const [nameOfOrganization, setNameOfOrganization] = useState<string>('');
  const [nameOfBranchOffice, setNameOfBranchOffice] = useState<string>('');
  const [prefecture, setPrefecture] = useState<string>('');
  const [municipality, setMunicipality] = useState<string>('');
  const [townStreetApartment, setTownStreetApartment] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [doesWorkInvolveInfoProcessingServices, setDoesWorkInvolveInfoProcessingServices] = useState<string>('');
  const [nameOfQualificationOrCertifiyingExam, setNameOfQualificationOrCertifiyingExam] = useState<string>('');

  const [nameOrOrganizationError, setNameOfOrganizationError] = useState<string>('');
  const [nameOfBranchOfficeError, setNameOfBranchOfficeError] = useState<string>('');
  const [townStreetApartmentError, setTownStreetApartmentError] = useState<string>('');
  const [phoneNumberError, setPhoneNumberError] = useState<string>('');
  const [nameOfQualificationOrCertifiyingExamError, setNameOfQualificationOrCertifiyingExamError] = useState<string>('');

  //Might be used in the future
  const isVisaExtension = isThisVisaExtension(visaApplicationType);
  const isVisaChange = isThisVisaChange(visaApplicationType);

  const getEmptyError = (val: any) => (isEmptyRequiredValueError && !!!val)? tWithoutPrefix("inputAlert.field"): '';

  //Currently, the item names for this section are common between visa extension and change, 
  //so using the same function.
  const saveData = (data: Partial<FormN>) => {
    onChangeFormN(data);
  }

  useEffect(() => {
    if (!formN) 
      return;

    setNameOfOrganization(formN["place_of_employment_name"] ?? '');
    setNameOfBranchOffice(formN["place_of_employment_name_of_branch"] ?? '');
    
    setPrefecture(formN["place_of_employment_prefecture"] ?? '');
    setMunicipality(formN["place_of_employment_municipality"] ?? '');
    setTownStreetApartment(formN["place_of_employment_street"] ?? '');

    setPhoneNumber(formN["place_of_employment_telephone_no"] ?? '');
    setDoesWorkInvolveInfoProcessingServices(formN["qualifications_for_information_processing"] ?? '');
    setNameOfQualificationOrCertifiyingExam(formN["name_of_the_qualification"] ?? '');

  }, [formN]);


  useEffect(() => {
    const conditionallyRequiredFields = [];
    
    if (doesWorkInvolveInfoProcessingServices === DoesWorkInvolveInInfoProcessing.Yes)
      conditionallyRequiredFields.push(nameOfQualificationOrCertifiyingExam);
        
    const areAllRequiredFieldsFilled = [
      nameOfOrganization,
      nameOfBranchOffice,
      prefecture,
      municipality,
      townStreetApartment,
      phoneNumber,
      doesWorkInvolveInfoProcessingServices,
      ...conditionallyRequiredFields,
    ].every(val => !!val);

    const areThereNoErrors = [
      nameOrOrganizationError,
      nameOfBranchOfficeError,
      townStreetApartmentError,
      phoneNumberError,
      nameOfQualificationOrCertifiyingExamError,
    ].every(val => !val);

    onSectionReadinessChange(
      areAllRequiredFieldsFilled && areThereNoErrors
    );
  }, [
    nameOfOrganization, 
    nameOfBranchOffice,
    prefecture,
    municipality,
    townStreetApartment,
    phoneNumber,
    doesWorkInvolveInfoProcessingServices,
    nameOfQualificationOrCertifiyingExam,
    nameOrOrganizationError,
    nameOfBranchOfficeError,
    townStreetApartmentError,
    phoneNumberError,
    nameOfQualificationOrCertifiyingExamError,
  ]);
  

  return (
    <Section>
      {/* Name (Company) */}
      <TextField
        required
        label={t('nameOfOrganization')}
        placeholder="tokuty k.k."
        value={nameOfOrganization}
        error={nameOrOrganizationError || getEmptyError(nameOfOrganization)}
        maxLength={172}
        validators={[validation.createLengthValidator(172)]}
        onValueChange={setNameOfOrganization}
        onErrorChange={setNameOfOrganizationError}
        onBlur={() => { 
          saveData({
            place_of_employment_name: nameOfOrganization,
          });
        }}
      />

      {/* Name of branch/office */}
      <TextField
        required
        label={t("nameOfBranchOffice")}
        placeholder="Tokyo Headquarters"
        value={nameOfBranchOffice}
        error={nameOfBranchOfficeError || getEmptyError(nameOfBranchOffice)}
        maxLength={172}
        validators={[validation.createLengthValidator(172)]}
        onValueChange={setNameOfBranchOffice}
        onErrorChange={setNameOfBranchOfficeError}
        onBlur={() => {
          saveData({
            place_of_employment_name_of_branch: nameOfBranchOffice,
          });
        }}
      />

      {/* Location */}
      <LocalFieldGroup 
        title={t('location')}
        note={t('noteOnLocation')}
        theme="light"
      >

        {/* Prefecture */}
        <SelectField
          required
          label={t('prefecture')}
          placeholder={tWithoutPrefix("placeholder.select")}
          options={PREFECTURE_WITH_JP_VALUE_OPTIONS}
          value={prefecture}
          error={getEmptyError(prefecture)}
          onValueChange={val => {
            setPrefecture(val);
            setMunicipality('');

            saveData({
              place_of_employment_prefecture: val,
              place_of_employment_municipality: '',
            });
          }}
        />

        {/* Municipality */}
        <SelectField
          disabled={!prefecture}
          required
          label={t('municipality')}
          placeholder={tWithoutPrefix("placeholder.select")}
          options={MUNICIPALITY_WITH_JP_VALUE_OPTIONS[prefecture] ?? []}
          value={municipality}
          error={prefecture 
            ? getEmptyError(municipality)
            : t('enterPrefectureFirst')
          }
          onValueChange={val => {
            setMunicipality(val);
            
            saveData({
              place_of_employment_municipality: val,
            });
          }}
        />

        {/* Town/Street/Apartment */}
        <MultiTextField
          required
          label={t('townStreetApartment')}
          placeholder="品川１ー２ー３ビザダスビル３F"
          value={townStreetApartment}
          error={townStreetApartmentError || getEmptyError(townStreetApartment)}
          maxLength={172}
          validators={[validation.createLengthValidator(172)]}
          onValueChange={setTownStreetApartment}
          onErrorChange={setTownStreetApartmentError}
          onBlur={() => {
            saveData({
              place_of_employment_street: townStreetApartment,
            })
          }}
        />
      </LocalFieldGroup>

      {/* Phone number */}
      <TextField
        required
        label={t('phoneNumber')}
        placeholder="0312345678"
        note={t('noteOnPhoneNumber')}
        value={phoneNumber}
        error={phoneNumberError || getEmptyError(phoneNumber)}
        restriction={tWithoutPrefix('inputNote.halfwidthNumberNoHyphens')}
        maxLength={12}
        validators={[validation.isNumeric, validation.createLengthValidator(12)]}
        onValueChange={setPhoneNumber}
        onErrorChange={setPhoneNumberError}
        onBlur={() => {
          saveData({
            place_of_employment_telephone_no: phoneNumber,
          });
        }}
      />


      {/* Does your work involve inforamtion processing services? (i.e. IT engineer) */}
      <RadioField
        required
        label={t('doesYourWorkInvolveInfoProcessingServices')}
        options={[
          {
            label: tWithoutPrefix('common.yes'),
            value: DoesWorkInvolveInInfoProcessing.Yes
          },
          {
            label: tWithoutPrefix('common.no'),
            value: DoesWorkInvolveInInfoProcessing.No
          },
        ]}
        value={doesWorkInvolveInfoProcessingServices}
        error={getEmptyError(doesWorkInvolveInfoProcessingServices)}
        onValueChange={val => {
          let data: Partial<FormN> = {};
          data["qualifications_for_information_processing"] = val;
          
          if (val === DoesWorkInvolveInInfoProcessing.No) {
            setNameOfQualificationOrCertifiyingExam('');
            setNameOfQualificationOrCertifiyingExamError('');
            data["name_of_the_qualification"] = '';
          }
          
          saveData(data);
          setDoesWorkInvolveInfoProcessingServices(val);
        }}
      />

    {/* Name of your qualification or certifying examination for inforamtion processing */}
    { doesWorkInvolveInfoProcessingServices === DoesWorkInvolveInInfoProcessing.Yes &&
      <TextField
        required
        label={t('nameOfQualificationExamForInfoProcessing')}
        placeholder="System Architect Examination"
        value={nameOfQualificationOrCertifiyingExam}
        error={nameOfQualificationOrCertifiyingExamError || getEmptyError(nameOfQualificationOrCertifiyingExam)}
        maxLength={172}
        validators={[validation.createLengthValidator(172)]}
        onValueChange={setNameOfQualificationOrCertifiyingExam}
        onErrorChange={setNameOfQualificationOrCertifiyingExamError}
        onBlur={() => {
          saveData({
            name_of_the_qualification: nameOfQualificationOrCertifiyingExam,
          });
        }}
      />
    }
    
    </Section>
  );
};

export default PlaceOfEmploymentSection;
//The label numbers come from the spreadsheet:
//See: https://docs.google.com/spreadsheets/d/1DSH9psUEakHitz56tuTrRxzV0y07nI_KhafCmHlUjCI/edit#gid=1606825711

export const INDUSTRY_OF_EMPLOYER_OPTIONS = [
  {
      "label": "industryOfEmployer.O05",
      "value": "食料品製造業"
  },
  {
      "label": "industryOfEmployer.O06",
      "value": "繊維工業製造業"
  },
  {
      "label": "industryOfEmployer.O07",
      "value": "プラスチック製品製造業"
  },
  {
      "label": "industryOfEmployer.O08",
      "value": "金属製品製造業"
  },
  {
      "label": "industryOfEmployer.O09",
      "value": "生産用機械器具製造業"
  },
  {
      "label": "industryOfEmployer.O10",
      "value": "電気機械器具製造業"
  },
  {
      "label": "industryOfEmployer.O11",
      "value": "輸送用機械器具製造業"
  },
  {
      "label": "industryOfEmployer.O12",
      "value": "その他の製造業(他に分類されないもの)"
  },
  {
      "label": "industryOfEmployer.O16",
      "value": "各種商品卸売業(総合商社等)"
  },
  {
      "label": "industryOfEmployer.O17",
      "value": "繊維・衣服等卸売業"
  },
  {
      "label": "industryOfEmployer.O18",
      "value": "飲食料品卸売業"
  },
  {
      "label": "industryOfEmployer.O19",
      "value": "建築材料，鉱物・金属材料等卸売業"
  },
  {
      "label": "industryOfEmployer.O20",
      "value": "機械器具卸売業"
  },
  {
      "label": "industryOfEmployer.O21",
      "value": "その他の卸売業"
  },
  {
      "label": "industryOfEmployer.O22",
      "value": "各種商品小売業"
  },
  {
      "label": "industryOfEmployer.O23",
      "value": "織物・衣服・身の回り品小売業"
  },
  {
      "label": "industryOfEmployer.O24",
      "value": "飲食料品小売業(コンビニエンスストア等)"
  },
  {
      "label": "industryOfEmployer.O25",
      "value": "機械器具小売業"
  },
  {
      "label": "industryOfEmployer.O26",
      "value": "その他の小売業"
  },
  {
      "label": "industryOfEmployer.O29",
      "value": "学術・開発研究機関"
  },
  {
      "label": "industryOfEmployer.O30",
      "value": "専門サービス業(他に分類されないもの)"
  },
  {
      "label": "industryOfEmployer.O31",
      "value": "広告業"
  },
  {
      "label": "industryOfEmployer.O32",
      "value": "技術サービス業(他に分類されないもの)"
  },
  {
      "label": "industryOfEmployer.O38",
      "value": "医療業"
  },
  {
      "label": "industryOfEmployer.O39",
      "value": "保健衛生"
  },
  {
      "label": "industryOfEmployer.O40",
      "value": "社会保険・社会福祉・介護事業"
  },
  {
      "label": "industryOfEmployer.O01",
      "value": "農林業"
  },
  {
      "label": "industryOfEmployer.O02",
      "value": "漁業"
  },
  {
      "label": "industryOfEmployer.O03",
      "value": "鉱業，採石業，砂利採取業"
  },
  {
      "label": "industryOfEmployer.O04",
      "value": "建設業"
  },
  {
      "label": "industryOfEmployer.O13",
      "value": "電気・ガス・熱供給・水道業"
  },
  {
      "label": "industryOfEmployer.O14",
      "value": "情報通信業"
  },
  {
      "label": "industryOfEmployer.O15",
      "value": "運輸・信書便事業"
  },
  {
      "label": "industryOfEmployer.O27",
      "value": "金融・保険業"
  },
  {
      "label": "industryOfEmployer.O28",
      "value": "不動産・物品賃貸業"
  },
  {
      "label": "industryOfEmployer.O33",
      "value": "宿泊業"
  },
  {
      "label": "industryOfEmployer.O34",
      "value": "飲食サービス業"
  },
  {
      "label": "industryOfEmployer.O35",
      "value": "生活関連サービス(理容・美容等)・娯楽業"
  },
  {
      "label": "industryOfEmployer.O36",
      "value": "学校教育"
  },
  {
      "label": "industryOfEmployer.O37",
      "value": "その他の教育，学習支援業"
  },
  {
      "label": "industryOfEmployer.O42",
      "value": "職業紹介・労働者派遣業"
  },
  {
      "label": "industryOfEmployer.O41",
      "value": "複合サービス事業(郵便局，農林水産業協同組合，事業協同組合(他に分類されないもの))"
  },
  {
      "label": "industryOfEmployer.O43",
      "value": "その他の事業サービス業(速記・ワープロ入力・複写業，建物サービス業，警備業等)"
  },
  {
      "label": "industryOfEmployer.O44",
      "value": "その他のサービス業(他に分類されないもの)"
  },
  {
      "label": "industryOfEmployer.O45",
      "value": "公務(他に分類されるものを除く)"
  },
  {
      "label": "industryOfEmployer.O46",
      "value": "分類不能の産業"
  },
  {
      "label": "industryOfEmployer.O47",
      "value": "宗教"
  }
]
//The label numbers come from the spreadsheet:
//See: https://docs.google.com/spreadsheets/d/1DSH9psUEakHitz56tuTrRxzV0y07nI_KhafCmHlUjCI/edit#gid=1606825711

export const TYPE_OF_CONTRACT_OPTIONS_FOR_TECHNICAL = [
  {
      "label": "typeOfContractForTechnical.0",
      "value": "雇用"
  },
  {
      "label": "typeOfContractForTechnical.1",
      "value": "委任"
  },
  {
      "label": "typeOfContractForTechnical.2",
      "value": "請負"
  },
  {
      "label": "typeOfContractForTechnical.9",
      "value": "その他"
  }
];
import { extractURLParameters, urlParameterExists } from "./utils";

export const getInvitationTokenFromURLParams = (): string | undefined => {
  const urlParams = extractURLParameters(false);

  if (!urlParams)
    return;

  return urlParams['invitation_token'];
}

export const isInvitationTokenIncludedInURLParams = (): boolean => {
  return urlParameterExists('invitation_token');
}

export const isThisSharedPage = (): boolean => {
  return window.location.pathname.includes('/shared');
}

export const isSharedPageAccess = (): boolean => {
  return (isInvitationTokenIncludedInURLParams() && isThisSharedPage());
}

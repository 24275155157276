import { FunctionComponent } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import LanguageSelector from "./LanguageSelector";
import { ReactComponent as HambergerIcon } from "./assets/icon-hamberger-menu.svg";
import { ReactComponent as CrossIcon } from "./assets/icon-cross.svg";
import { ReactComponent as PulldownArrowIcon } from "./assets/icon-expand.svg";
import { ReactComponent as BrandLogo } from "./assets/brand-logo.svg";
import { useComponentVisible, useDispatcher } from "./hooks";
import { Link, useNavigate } from "react-router-dom";
import { signOut } from "./apis/auth";

interface ContainerProps {
    isLoggedIn: boolean;
}

interface MenuProps {
    visible?: boolean;
}

const Container = styled.div<ContainerProps>`
    width:100%;
    padding:5px 25px 5px 20px;
    background-color: ${(props: ContainerProps) => props.isLoggedIn ? '#FFFFFF' : 'transparent'};
`;

const Inner = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const MenuWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Menu = styled.div<MenuProps>`
  position: fixed;
  top: 44px;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 5;
  cursor: unset;
  background-color: #FAF6F4;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  visibility: ${(props: MenuProps) => (props.visible ? "visible" : "hidden")};
`;

const MenuItem = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 38px 0 30px;
  background-color: #ffffff;
  text-decoration: none;
  border: 0.5px solid #e5e5e5;
  color: #2c2c2c;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 16px;
  font-weight: 700;
  width: 100%;
  height: 48px;

  &:hover {
    background: #0069EC;
    color: #ffffff;
  }

  &:hover path {
    fill: #ffffff;
  }
`;

const VisadasLogo = styled(BrandLogo)`
  cursor: pointer;
`;

const ArrowIcon = styled(PulldownArrowIcon)`
  transform: rotate(-90deg);
`;

const MenuIcon = styled(HambergerIcon)`
  cursor: pointer;
  path {
    fill: #444444;
  }
`;

const MenuCloseIcon = styled(CrossIcon)`
  cursor: pointer;
  width: 30px;
  height: 26px;
  path {
    fill: #444444;
  }
`;

const NavBar: FunctionComponent = () => {
  const { state, dispatcher } = useDispatcher();
  const { t } = useTranslation();
  const { ref, visible, setVisible } = useComponentVisible(false);
  const closeMenu = () => setVisible(false);
  const navigate = useNavigate();
  const signOutAndCloseMenu = async () => {
    dispatcher.startLoading(t("spinner.loggingOut"));
    try {
      dispatcher.logout();
      await signOut();
      navigate("/login");
      dispatcher.showSnackbar(t("snackbar.logoutSuccess"), "success");
    } catch (e) {
    } finally {
      setVisible(false);
      dispatcher.stopLoading();
    }
  };

  return (
    <Container isLoggedIn={state.isLoggedIn}>
      <Inner>
        <VisadasLogo onClick={() => navigate("/")} />
        {state.isLoggedIn ? (
          <MenuWrapper ref={ref} onClick={() => setVisible(!visible)}>
            {visible 
              ? <MenuCloseIcon /> 
              : <MenuIcon />
            }
            
            <Menu visible={visible}>
              <MenuItem to="/" onClick={closeMenu}>
                {t("navbar.home")}
                <ArrowIcon />
              </MenuItem>
              <MenuItem to="/account" onClick={closeMenu}>
                {t("navbar.account")}
                <ArrowIcon />
              </MenuItem>
              <MenuItem
                to="/login"
                onClick={signOutAndCloseMenu}
                style={{ marginTop: 45 }}
              >
                {t("navbar.logout")}
                <ArrowIcon />
              </MenuItem>
            </Menu>
          </MenuWrapper>
        ) : (
          <LanguageSelector />
        )}
      </Inner>
    </Container>
  );
};

export default NavBar;

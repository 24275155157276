import { FunctionComponent, useEffect, useState } from "react";
import { ExtensionBySelfData } from "../../../../../../types/extensionBySelf/data";
import { ChangeBySelfData } from "../../../../../../types/changeBySelf/data";
import {
  formatDate,
  getLabelFromFamilyRelationCode,
  getLabelFromNationalityCode,
  getLengthOfZincSnzkList,
  getTransKeyForFamilyInJapanOrCohabitants,
  getTransKeyForLivingTogetherWithThisFamilyOrCohabitant,
} from "../../../../../../utils/visaFormDataHelper";
import { useTranslation } from "react-i18next";
import { PreviewItem } from "../../../../../../types/modal/preview";
import {
  isChangeBySelfData,
  isExtensionBySelfData,
} from "../../../../../../utils/visaApplicationHelper";
import {
  Indent,
  PreviewHeading
} from "../../previewBodyCommonStyle";
import { generateRandomString } from "../../../../../../utils/utils";
import PreviewList from "../../../PreviewList";
import FieldGroupNumber from "../../../../../display/FieldGroupNumber";
import styled from "styled-components";
import Column from "../../../../../layout/Column";

interface FamilyCoresidentsSectionProps {
  data: ExtensionBySelfData | ChangeBySelfData;
}

const LocalColumn = styled(Column)`
  margin-top: 10px;
  gap: 10px;
`;

export const FamilyCoresidentsSection: FunctionComponent<
  FamilyCoresidentsSectionProps
> = ({ data }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'familyCoresidentsSection' });
  const { t : tWithoutPrefix } = useTranslation();
  const length = getLengthOfZincSnzkList(data);
  const [previewItemsList, setPreviewItemsList] = useState<PreviewItem[][]>([]);

  useEffect(() => {
    const itemsList: PreviewItem[][] = [];

    for (let i = 1; i <= length; i++) {
      const items: PreviewItem[] = [];

      if (isExtensionBySelfData(data)) {
        items.push(
          {
            label: t("relationship"),
            value: tWithoutPrefix(`${
                getLabelFromFamilyRelationCode(
                  data[
                    `WCIBS010Dto:zincSnzkList[${i}].selZkgr` as keyof ExtensionBySelfData
                  ] as string
                ) ?? ""
              }`
            ),
          },
          {
            label: t("name"),
            value: data[
              `WCIBS010Dto:zincSnzkList[${i}].txtName` as keyof ExtensionBySelfData
            ] as string,
          },
          {
            label: t("birthdate"),
            value: formatDate(
              data[
                `WCIBS010Dto:zincSnzkList[${i}].selDateOfBirthYear` as keyof ExtensionBySelfData
              ] as string,
              data[
                `WCIBS010Dto:zincSnzkList[${i}].selDateOfBirthMonth` as keyof ExtensionBySelfData
              ] as string,
              data[
                `WCIBS010Dto:zincSnzkList[${i}].selDateOfBirthDay` as keyof ExtensionBySelfData
              ] as string
            ),
          },
          {
            label: t("nationality"),
            value: tWithoutPrefix(`${
                getLabelFromNationalityCode(
                  data[
                    `WCIBS010Dto:zincSnzkList[${i}].selNationalityAndRegion` as keyof ExtensionBySelfData
                  ] as string
                ) ?? ""
              }`
            ),
          },
          {
            label: t("livigingTogether"),
            value: tWithoutPrefix(`${
              getTransKeyForLivingTogetherWithThisFamilyOrCohabitant(
                data[
                  `WCIBS010Dto:zincSnzkList[${i}].radDukyUm` as keyof ExtensionBySelfData
                ] as string
              )}`
            ),
          },
          {
            label: t("employmentOrSchool"),
            value: data[
              `WCIBS010Dto:zincSnzkList[${i}].txtWorkPlaceOrTugkskName` as keyof ExtensionBySelfData
            ] as string,
          },
          {
            label: t("residenceCardNumber"),
            value: data[
              `WCIBS010Dto:zincSnzkList[${i}].txtZiryCardNumOrTkeiNum` as keyof ExtensionBySelfData
            ] as string,
          }
        );
      }

      if (isChangeBySelfData(data)) {
        items.push(
          {
            label: t("relationship"),
            value: data[
              `WCIBS010Dto:zincSnzkList[${i}].selZkgr` as keyof ChangeBySelfData
            ] as string,
          },
          {
            label: t("name"),
            value: data[
              `WCIBS010Dto:zincSnzkList[${i}].txtName` as keyof ChangeBySelfData
            ] as string,
          },
          {
            label: t("birthdate"),
            value: formatDate(
              data[
                `WCIBS010Dto:zincSnzkList[${i}].selDateOfBirthYear` as keyof ChangeBySelfData
              ] as string,
              data[
                `WCIBS010Dto:zincSnzkList[${i}].selDateOfBirthMonth` as keyof ChangeBySelfData
              ] as string,
              data[
                `WCIBS010Dto:zincSnzkList[${i}].selDateOfBirthDay` as keyof ChangeBySelfData
              ] as string
            ),
          },
          {
            label: t("nationality"),
            value:
              t(
                `${getLabelFromNationalityCode(
                  data[
                    `WCIBS010Dto:zincSnzkList[${i}].selNationalityAndRegion` as keyof ChangeBySelfData
                  ] as string
                )}`
              ) ?? "",
          },
          {
            label: t("livigingTogether"),
            value: t(
              `${getTransKeyForLivingTogetherWithThisFamilyOrCohabitant(
                data[
                  `WCIBS010Dto:zincSnzkList[${i}].radDukyUm` as keyof ChangeBySelfData
                ] as string
              )}`
            ),
          },
          {
            label: t("employmentOrSchool"),
            value: data[
              `WCIBS010Dto:zincSnzkList[${i}].txtWorkPlaceOrTugkskName` as keyof ChangeBySelfData
            ] as string,
          },
          {
            label: t("residenceCardNumber"),
            value: data[
              `WCIBS010Dto:zincSnzkList[${i}].txtZiryCardNumOrTkeiNum` as keyof ChangeBySelfData
            ] as string,
          }
        );
      }

      itemsList.push(items);
    }

    setPreviewItemsList(itemsList);
  }, []);

  
  return (
    <section>
      <PreviewHeading>{t("title")}</PreviewHeading>
      <PreviewList
        items={[
          {
            label: t("familyInJapan"),
            value: tWithoutPrefix(
              `${getTransKeyForFamilyInJapanOrCohabitants(
                data["WCIBS010Dto:radZincSnzkUm1"]
              )}`
            ),
          },
        ]}
      />
      {previewItemsList.length > 0 && (
        <Indent>
          {previewItemsList.map((items, i) => (
            <LocalColumn key={generateRandomString()}>
              <FieldGroupNumber>{i + 1}</FieldGroupNumber>
              <PreviewList items={items} />
            </LocalColumn>
          ))}
        </Indent>
      )}
    </section>
  );
};

export default FamilyCoresidentsSection;

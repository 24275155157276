import { FunctionComponent } from "react";
import styled from "styled-components";
import Text from "../../text/Text";
import { PreviewAttachment, PreviewItem } from "../../../types/modal/preview";
import { convertBytesToMB } from "../../../utils/visaFormDataHelper";
import { useTranslation } from "react-i18next";
import { generateRandomString } from "../../../utils/utils";
import DisplayLabel from "../../form/DisplayLabel";
import { isThisPreviewAttachment, isThisPreviewItem } from "../../../utils/previewHelper";

interface PreviewListProps {
  items: (PreviewItem | PreviewAttachment) [];
  areOddRowsWhite?: boolean;
}

interface ListRowProps {
  areOddRowsWhite?: boolean;
}

const ListContainer = styled.div`
  width: 100%;
`;

const ListRow = styled.div<ListRowProps>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 10px 20px 10px 20px;
  align-items: center;
  min-height: 40px;
  gap: 20px;
  background-color: ${({ areOddRowsWhite }) =>
    areOddRowsWhite ? "#F7F7F7" : "#ffffff"};

  &:nth-child(odd) {
    background-color: ${({ areOddRowsWhite }) =>
      areOddRowsWhite ? "#ffffff" : "#F7F7F7"};
  }
`;

const AttachmentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1 / 3;
  gap: 10px;
  align-items: center;
`;

const AttachmentLabelText = styled(Text)`
  color: #999999;
  display: block;
  width: 100%;
  text-align: left;
`;

const LocalDisplayLabel = styled(DisplayLabel)`
  word-break: break-all;
  color: #999999;
`;

const LocalText = styled(Text)`
  word-break: break-all;
`;

const TextImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
`;

const Image = styled.img`
  width: 100%;
  max-width: 320px;
  height: auto;
`;

const PreviewList: FunctionComponent<PreviewListProps> = ({
  items,
  areOddRowsWhite
}) => {
  const { t } = useTranslation();

  return (
    <ListContainer>
      {items
        .filter(v => !!!v.skip)
        .map(item => (
          <ListRow 
            key={generateRandomString()}
            areOddRowsWhite={areOddRowsWhite}
          >

            { isThisPreviewAttachment(item) && (
              <AttachmentWrapper>
                <AttachmentLabelText>{item.label}</AttachmentLabelText>
    
                {item.files.length === 0 && (
                  <Text>{t("attachmentsSection.noFilesAttached")}</Text>
                )}
    
                {item.files.map(file => {
                  return (
                    <TextImageWrapper key={generateRandomString()}>
                      <Text color="#999999">{convertBytesToMB(file.size)} MB</Text>
                      <Image src={file.url} />
                    </TextImageWrapper>
                  );
                })}
              </AttachmentWrapper>)
            }


            { isThisPreviewItem(item) && (
              <>
                <LocalDisplayLabel>{item.label}</LocalDisplayLabel>
                <LocalText>{item.value ?? ""}</LocalText>
              </>
            )}

          </ListRow>
        ))}
    </ListContainer>
  );
};

export default PreviewList;

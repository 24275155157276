import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Modal from "../../components/modal/Modal";
import Button from "../../components/form/Button";
import Column from "../../components/layout/Column";
import { CONTENT_MAX_WIDTH } from "../../constants/styles";
import { useDispatcher } from "../../hooks";
import PageHeading from "../../components/text/PageHeading";
import { useNavigate } from "react-router-dom";
import BackButton from "../../components/navigation/BackButton";
import { createVisaApplication } from "../../apis/visaApplication";
import { createExtensionBySelfData } from "../../apis/extensionBySelfData";
import { getCurrentUser } from "../../apis/user";
import RadioField from "../../components/compound/RadioField";
import { 
  convertToApplicationCategoryCodeForExtensionBySelf, 
  convertToCurrentStatusOfResidenceCode, 
  convertToStatusOfResidenceCodeForExtensionBySelf 
} from "../../utils/visaFormDataHelper";
import { KeptVisaApplicationContent, No } from "../../types/extensionBySelf/specificValues";
import { StatusOfResidenceOfUser } from "../../types/user/data";
import { createFormN } from "../../apis/formN";


interface CreateNewAppModalProps {
  onClose: () => void;
}

//Defined as enum even if only one value is used for now
//This is because a new screen can be added later
enum ContentType {
  CreateApplication = 'create-application',
}

enum ApplicationType {
  Extension = 'extension',
  Change = 'change',
}

const ContentContainer = styled.div`
  background-color: #ffffff;
  width: 100%;
  height: 100%;
`;

const Content = styled(Column)`
  padding: 20px;
  max-width: ${CONTENT_MAX_WIDTH}px;
  height: 100%;
  margin: 0 auto;
`;

const UpperPartContainer = styled(Column)`
  gap: 20px;
`;

const LowerPartContainer = styled.div`
  margin-top: 58px;
  width: 100%;

  @media (max-width: ${CONTENT_MAX_WIDTH}px) {
    flex-grow: 1;
    display: flex;
    flex-direction: column-reverse;
  }
`;


const CreateNewAppModal: FunctionComponent<CreateNewAppModalProps> = ({
  onClose,
}) => {
  const { dispatcher } = useDispatcher();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [contentType] = useState(ContentType.CreateApplication);
  const [appType, setAppType] = useState<ApplicationType>();
  const onClickBack = () => {
    switch (contentType) {
      case ContentType.CreateApplication:
        onClose();
        return;
    }
  };
  const onCreate = async () => {
    dispatcher.startLoading();
    try {
      const user = await getCurrentUser();
      const visaApplication = await createVisaApplication();
      const statusOfResidenceOfUser = user.status_of_residence;

      switch (statusOfResidenceOfUser) {
        case StatusOfResidenceOfUser.Technical:
          await createFormN(visaApplication.id);
      }

      if (appType === ApplicationType.Extension) {
        await createExtensionBySelfData(
          visaApplication.id,
          {
            "No.": No, //This value ('1') must be used for this kind of application,
            "WCIBS010Dto:selCurrentZirySkk": convertToCurrentStatusOfResidenceCode(user.status_of_residence),
            "WZAAS190Dto:chkHkeDui": KeptVisaApplicationContent.Yes, //We only use Yes
            selSnsiShbt: convertToApplicationCategoryCodeForExtensionBySelf(user.status_of_residence),
            selZirySkk: convertToStatusOfResidenceCodeForExtensionBySelf(user.status_of_residence)
          }
        );
      }

      if (appType === ApplicationType.Change) {
        //TODO: Implement later (currently, this "Change" option is disabled)
      }

      navigate(`/application/${visaApplication.id}/edit/`);
      dispatcher.showSnackbar(t("snackbar.appCreated"), 'success');
    } catch (e) {
      dispatcher.showSnackbar(t("snackbar.failedToCreateApp"), 'warning');
    } finally {
      dispatcher.stopLoading();
    }
  }


  return (
    <Modal>
      <ContentContainer>
        <Content>
          <UpperPartContainer>
            <BackButton onClick={onClickBack}/>
            <PageHeading>{t("modalCreateApp.createApplication")}</PageHeading>
            <RadioField
              label={t("modalCreateApp.chooseApplication")}
              options={[
                {
                  label: t("modalCreateApp.extendPeriodOfStay"),
                  value: ApplicationType.Extension
                },
                {
                  label: t("modalCreateApp.changeStatusOfResidence"),
                  value: ApplicationType.Change,
                  disabled: true
                }
              ]}
              value={appType}
              onValueChange={setAppType}
            />
          </UpperPartContainer>
          <LowerPartContainer>
            <Button
              disabled={
                (contentType === ContentType.CreateApplication && !appType) 
              }
              variant="primary"
              style={{ width: '100%' }}
              onClick={onCreate}
            >
              {t("modalCreateApp.create")}
            </Button>
          </LowerPartContainer>
        </Content>
      </ContentContainer>
    </Modal>
  );
};

export default CreateNewAppModal;

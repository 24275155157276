import { RELATIONSHIP_OPTIONS, MUNICIPALITY_OPTIONS, ALL_NATIONALITY_OPTIONS, PERIOD_OF_STAY_OPTIONS_FOR_DEPENDENT, PREFECTURE_OPTIONS, DESIRED_PERIOD_OF_STAY_FOR_DEPENDENT, PREFECTURE_WITH_JP_VALUE_OPTIONS, MUNICIPALITY_WITH_JP_VALUE_OPTIONS, DESIRED_PERIOD_OF_STAY_OPTIONS_FOR_TECHNICAL, PERIOD_OF_STAY_OPTIONS_FOR_TECHNICAL } from "../constants/options";
import { TYPE_OF_CONTRACT_OPTIONS_FOR_TECHNICAL } from "../constants/options/contract";
import { FINAL_EDUCATION_OPTIONS_FOR_TECHNICAL } from "../constants/options/education";
import { INDUSTRY_OF_EMPLOYER_OPTIONS } from "../constants/options/industry";
import { MAJOR_FIELD_OF_STUDY_FOR_COLLEGE_OF_TECHNOLOGY_OPTIONS_FOR_TECHNICAL, MAJOR_FIELD_OF_STUDY_FOR_DRMSBCJC_OPTIONS_FOR_TECHNICAL } from "../constants/options/major";
import { OFFICE_OPTIONS } from "../constants/options/office";
import { TIME_PERIOD_MONTH_OPTIONS, TIME_PERIOD_YEAR_OPTIONS } from "../constants/options/timePeriod";
import { START_DATE_OF_EMPLOYMENT_UNDECIDED_OPTIONS, TYPE_OF_WORK_OPTIONS } from "../constants/options/work";
import { MAX_FAMILY_CORESIDENTS, MAX_WORK_HISTORIES } from "../constants/values";
import { 
  ApplicationCategoryCode as ApplicationCategoryCodeForExtensionBySelf, 
  StatusOfResidenceCode as StatusOfResidenceCodeForExtensionBySelf
} from "../types/extensionBySelf/specificValues";
import { FormN } from "../types/uncommonFormParts/formN/data";
import { DoesApplicantHavePositionOrTitle, DoesWorkInvolveInInfoProcessing, LocationOfFinalEducation, ScheduledPeriodOfEmployment, StartDateOfEmployment } from "../types/uncommonFormParts/formN/value";
import { FirstRenewalApplication } from "../types/uncommonFormParts/supplementaryInfo";
import { StatusOfResidenceOfUser } from "../types/user/data";
import { VisaAttachmentKind } from "../types/visa/attachment";
import { VisaAttachment } from "../types/visa/attachment";
import { CurrentStatusOfResidenceCode } from "../types/visa/currentStatusOfResidence";
import { CrimePunishmentStatus, FamilyInJapanOrCoresidents, Gender, HowToReceiveNewResidenceCard, LivingTogetherWithThisFamilyOrCoresident, MaritalStatus } from "../types/visa/formCommonValues";


export const parseName = (name: string | undefined | null, delimiter: string)=> {
  const [familyName, givenName, ...middleArr] = (name ?? '').split(delimiter);
  return { 
    familyName: familyName ?? '', 
    givenName: givenName ?? '',
    middleName: middleArr.join(delimiter) ?? ''
  };
}

export const formatDate = (year: string | undefined, month: string | undefined, day: string | undefined, separator?: string) => {
  if (year && month && day) {
    return [year, month, day].join(separator ?? '/');
  }

  return '';
}

//This converts a short amount (e.g. 1) to a full amount (e.g. 1000)
//This sometimes appear in the form, for example, the amount of tax paid last year.
export const convertToFullAmount = (amount: string | undefined) => {
  if (!amount)
    return '';
  
  const converted = Number(amount);
  if (isNaN(converted)) 
    return ''

  return String(converted * 1000);
}

export const getTransKeyForGender = (gender: string) => {
  switch (gender) {
    case Gender.Male: return "male";
    case Gender.Female: return "female";
    default: return "";
  }
}

export const getTransKeyForMaritalStatus = (maritalStatus: string) => {
  switch (maritalStatus) {
    case MaritalStatus.Married: return "married";
    case MaritalStatus.Single: return "single";
    default: return "";
  }
} 

export const getTransKeyForCrimePunishmentStatus = (crimePunishmentStatus: string) => {
  switch (crimePunishmentStatus) {
    case CrimePunishmentStatus.Punished: return "common.yes";
    case CrimePunishmentStatus.None: return "common.no";
    default: return "";
  }
}

export const getTransKeyForFamilyInJapanOrCohabitants = (familyInJapanOrCohabitants: string) => {
  switch (familyInJapanOrCohabitants) {
    case FamilyInJapanOrCoresidents.Present: return "common.yes";
    case FamilyInJapanOrCoresidents.None: return "common.no";
    default: return "";
  }
}

export const getTransKeyForLivingTogetherWithThisFamilyOrCohabitant = (livingTogetherWithThisFamilyOrCohabitant: string) => {
  switch (livingTogetherWithThisFamilyOrCohabitant) {
    case LivingTogetherWithThisFamilyOrCoresident.Yes: return "common.yes";
    case LivingTogetherWithThisFamilyOrCoresident.No: return "common.no";
    default: return "";
  }
}

export const getTransKeyForHowToReceiveNewResidenceCard = (howToReceiveNewResidenceCard: string) => {
  switch (howToReceiveNewResidenceCard) {
    case HowToReceiveNewResidenceCard.Mail: return "byMail";
    case HowToReceiveNewResidenceCard.VisitImmigrationOffice: return "pickUpAtImmigrationOffice";
    default: return "";
  }
}

export const getTransKeyForStartDateOfEmployment = (startDateOfEmploymentVal: string) => {
  switch (startDateOfEmploymentVal) {
    case StartDateOfEmployment.Decided: return "decided";
    case StartDateOfEmployment.Undecided: return "undecided";
    default: return "";
  }
}

export const getTransKeyForWorkInvolveInfoProcessingService = (workInvolveInfoProcessingService: string) => {
  switch (workInvolveInfoProcessingService) {
    case DoesWorkInvolveInInfoProcessing.Yes: return "common.yes";
    case DoesWorkInvolveInInfoProcessing.No: return "common.no";
    default: return "";
  }
}

export const getTransKeyForFinalEducationLocation = (finalEducationLocation: string) => {
  switch (finalEducationLocation) {
    case LocationOfFinalEducation.Japan: return "japan";
    case LocationOfFinalEducation.ForeignCountry: return "foreignCountry";
    default: return "";
  }
}

export const getTransKeyForHavingPositionTitle = (havingPositionTitle: string) => {
  switch (havingPositionTitle) {
    case DoesApplicantHavePositionOrTitle.Yes: return "common.yes";
    case DoesApplicantHavePositionOrTitle.No: return "common.no";
    default: return "";
  }
}

export const getTransKeyForScheduledPeriodOfEmployment = (scheduledPeriodOfEmployment: string) => {
  switch (scheduledPeriodOfEmployment) {
    case ScheduledPeriodOfEmployment.Fixed: return "fixed";
    case ScheduledPeriodOfEmployment.NonFixed: return "nonFixed";
    default: return "";
  }
}

export const getTransKeyForFirstRenewal = (firstRenewal: string) => {
  switch (firstRenewal) {
    case FirstRenewalApplication.Yes: return "common.yes";
    case FirstRenewalApplication.No: return "common.no";
    default: return "";
  }
}

export const getLabelFromPeriodOfStayCodeForDependent = (periodOfStayCode: string) => {
  return PERIOD_OF_STAY_OPTIONS_FOR_DEPENDENT.find(op => op.value === periodOfStayCode)?.label ?? '';
}

export const getLabelFromPeriodOfStayCodeForTechnical = (periodOfStayCode: string) => {
  return PERIOD_OF_STAY_OPTIONS_FOR_TECHNICAL.find(op => op.value === periodOfStayCode)?.label ?? '';
}

export const getLabelFromDesiredPeriodOfStay = (desiredPeriodOfStayCode: string) => {
  if (!desiredPeriodOfStayCode)
    return "";

  //e.g. "T440001000" -> "T44"
  const currentStatusOfResidenceCode = desiredPeriodOfStayCode.slice(0, 3);
  switch (currentStatusOfResidenceCode) {
    case CurrentStatusOfResidenceCode.Dependent: 
      return DESIRED_PERIOD_OF_STAY_FOR_DEPENDENT
        .find(op => op.value === desiredPeriodOfStayCode)?.label ?? '';
    case CurrentStatusOfResidenceCode.EngineerOrSpecialistInHumanitiesOrInternationalServices:
      return DESIRED_PERIOD_OF_STAY_OPTIONS_FOR_TECHNICAL
        .find(op => op.value === desiredPeriodOfStayCode)?.label ?? '';
    default: 
      return "";
  }
}

export const getLabelFromNationalityCode = (nationalityCode: string) => {
  return ALL_NATIONALITY_OPTIONS.find(op => op.value === nationalityCode)?.label ?? '';
}

//The purpose of this file is to provide util parsing methods 
//because we need to parse many values to get the corresponding values
export const getLabelsFromAddressCode = (addressCode: string) => {
  const prefectureCode = addressCode?.slice(0, 2);
  const municipalityCode = addressCode?.slice(2);

  return {
    prefecture: PREFECTURE_OPTIONS.find(op => op.value === prefectureCode)?.label ?? '',
    municipality: MUNICIPALITY_OPTIONS[prefectureCode]?.find(op => op.value === municipalityCode)?.label ?? ''
  }
}

export const getLabelFromPrefectureInJpValue = (prefectureInJp: string) => {
  return PREFECTURE_WITH_JP_VALUE_OPTIONS.find(op => op.value === prefectureInJp)?.label ?? '';
}

export const getLabelFromMunicipalityInJpValue = (prefectureInJp: string, municipalityInJp: string) => {
  const municipalities = MUNICIPALITY_WITH_JP_VALUE_OPTIONS[prefectureInJp];
  if (!municipalities)
    return "";

  return municipalities.find(op => op.value === municipalityInJp)?.label ?? '';
}

//Pass a family-relation code (e.g. "01") to find out the corresponding label 
export const getLabelFromFamilyRelationCode = (familyRelationCode: string) => {
  return RELATIONSHIP_OPTIONS.find(op => op.value === familyRelationCode)?.label ?? '';
}

export const getLabelFromOfficeCode = ( officeCode: string, prefectureCode: string, municipalityCode: string) => {
  const options = OFFICE_OPTIONS[`${prefectureCode}${municipalityCode}`] 
    ?? OFFICE_OPTIONS[prefectureCode]
    ?? [];
  return options.find(op => op.value === officeCode)?.label ?? '';
}

export const getLabelFromFinalEducation = (finalEducation: string) => {
  return FINAL_EDUCATION_OPTIONS_FOR_TECHNICAL.find(op => op.value === finalEducation)?.label ?? '';
}

export const getLabelFromMajorFieldOfStudyForDrMsBcJc = (majorFieldOfStudy: string) => {
  return MAJOR_FIELD_OF_STUDY_FOR_DRMSBCJC_OPTIONS_FOR_TECHNICAL.find(op => op.value === majorFieldOfStudy)?.label ?? '';
}

export const getLabelFromMajorFieldOfStudyForCollegeOfTech = (majorFieldOfStudy: string) => {
  return MAJOR_FIELD_OF_STUDY_FOR_COLLEGE_OF_TECHNOLOGY_OPTIONS_FOR_TECHNICAL.find(op => op.value === majorFieldOfStudy)?.label ?? '';
}

export const getLabelFromIndustryOfEmployer = (industryOfEmployer: string) => {
  return INDUSTRY_OF_EMPLOYER_OPTIONS.find(op => op.value === industryOfEmployer)?.label ?? '';
}

export const getLabelFromTypeOfWork = (typeOfWork: string) => {
  return TYPE_OF_WORK_OPTIONS.find(op => op.value === typeOfWork)?.label ?? '';
}

export const getLabelFromTypeOfContract = (typeOfContract: string) => {
  return TYPE_OF_CONTRACT_OPTIONS_FOR_TECHNICAL.find(op => op.value === typeOfContract)?.label ?? '';
}

export const getLabelFromStartDateOfEmploymentUndecided = (startDateOfEmploymentUndecided: string) => {
  return START_DATE_OF_EMPLOYMENT_UNDECIDED_OPTIONS.find(op => op.value === startDateOfEmploymentUndecided)?.label ?? '';
}

export const getLabelFromTimePeriodYear = (timePeriod: string) => {
  return  TIME_PERIOD_YEAR_OPTIONS.find(op => op.value === timePeriod)?.label ?? '';
}

export const getLabelFromTimePeriodMonth = (timePeriod: string) => {
  return TIME_PERIOD_MONTH_OPTIONS.find(op => op.value === timePeriod)?.label ?? '';
}

//Pass the status_of_residence value (e.g. 'dependent') defined in the User object,
//and get the corresponding current status of residence code (*現に有する在留資格 - e.g. 'T61').
//This value is common to visa extension and change applications
export const convertToCurrentStatusOfResidenceCode = (statusOfResidence: StatusOfResidenceOfUser) => {
  switch (statusOfResidence) {
    case StatusOfResidenceOfUser.Dependent:
      return CurrentStatusOfResidenceCode.Dependent;
    case StatusOfResidenceOfUser.Technical:
      return CurrentStatusOfResidenceCode.EngineerOrSpecialistInHumanitiesOrInternationalServices;
    default:
      return "";
  }
}

//Pass a current status of residence code (e.g. "T44") and return a translation key (e.g. "dependent")
export const convertCurrentStatusOfResidenceToTransKey = (statusOfResidenceCode: CurrentStatusOfResidenceCode | string) => {
  switch (statusOfResidenceCode) {
    case CurrentStatusOfResidenceCode.Dependent:
      return "dependent";
    case CurrentStatusOfResidenceCode.EngineerOrSpecialistInHumanitiesOrInternationalServices:
      return "technical";
    default: 
      return "";
  }
}

//Pass the status_of_residence value (e.g. 'dependent') defined in the User object,
//and get the corresponding status of residence code (*在留資格 - e.g. '04R1') for the ExtensionBySelf type application
export const convertToStatusOfResidenceCodeForExtensionBySelf = (statusOfResidence: StatusOfResidenceOfUser) => {
  switch (statusOfResidence) {
    case StatusOfResidenceOfUser.Dependent:
      return StatusOfResidenceCodeForExtensionBySelf.Family;
    case StatusOfResidenceOfUser.Technical:
      return StatusOfResidenceCodeForExtensionBySelf.Technical;
    default:
      return "";
  }
}

//Pass the status_of_residence value (e.g. 'dependent') in the User object,
//and get the corresponding application category code (*申請種別 - e.g. '04R') for the ExtensionBySelf type application
export const convertToApplicationCategoryCodeForExtensionBySelf = (statusOfResidence: StatusOfResidenceOfUser) => {
  switch (statusOfResidence) {
    case StatusOfResidenceOfUser.Dependent:
      return ApplicationCategoryCodeForExtensionBySelf.Family;
    case StatusOfResidenceOfUser.Technical:
      return ApplicationCategoryCodeForExtensionBySelf.Technical;
    default:
      return "";
  }
}


//Expect to receive ExtensionBySelfData or ChangeBySelfData
//(these share the same properties used here)
export const getLengthOfZincSnzkList = <T> (data: T): number => {
  let length = 0;

  for (let i = 1; i <= MAX_FAMILY_CORESIDENTS; i++)
  {
    const isThereValue = [
      data[`WCIBS010Dto:zincSnzkList[${i}].selNationalityAndRegion` as keyof T],
      data[`WCIBS010Dto:zincSnzkList[${i}].txtName` as keyof T],
      data[`WCIBS010Dto:zincSnzkList[${i}].selDateOfBirthYear` as keyof T],
      data[`WCIBS010Dto:zincSnzkList[${i}].selDateOfBirthMonth` as keyof T],
      data[`WCIBS010Dto:zincSnzkList[${i}].selDateOfBirthDay` as keyof T],
      data[`WCIBS010Dto:zincSnzkList[${i}].selZkgr` as keyof T],
      data[`WCIBS010Dto:zincSnzkList[${i}].radDukyUm` as keyof T],
      data[`WCIBS010Dto:zincSnzkList[${i}].txtWorkPlaceOrTugkskName` as keyof T],
      data[`WCIBS010Dto:zincSnzkList[${i}].txtZiryCardNumOrTkeiNum` as keyof T]
    ].some(v => !!v);

    if (isThereValue) {
      length = i;
    }
  }

  return length;
}

export const getWorkHistoryLength = (data: FormN): number => {
  let length = 0;

  for (let i = 1; i <= MAX_WORK_HISTORIES; i++) 
  {
    const isThereValue = [
      data[`work_experience_${i}_place_of_employment` as keyof FormN],
      data[`work_experience_${i}_date_of_joining` as keyof FormN],
      data[`work_experience_${i}_date_of_leaving` as keyof FormN]
    ].some(v => !!v);

    if (isThereValue) {
      length = i;
    }
  }

  return length;
}

export const extractVisaAttachments = (visaAttachments?: VisaAttachment [], kind?: VisaAttachmentKind): VisaAttachment[] => {
  if (!visaAttachments || !kind)
    return [];

  return visaAttachments.filter(va => va.kind === kind);
}

export const convertBytesToMB = (bytes: number, digits: number = 1) => {
  const megabytes = bytes / (1024 * 1024);
  return megabytes.toFixed(digits); 
}
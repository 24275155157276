import { User } from "../types/user/data";
import { axiosInstance, hasErrorStatus, throwRequestError } from "./common";


export const sendInvitationToken = async (visaApplicationId: number | string) => {
  let response = await axiosInstance.request({
      method: 'PUT',
      url: `users/set_invitation_token`
  });

  if (hasErrorStatus(response))
    throwRequestError('getInvitationToken - set_invitation_token', response.status);

  const updatedUser = response.data as User;

  response = await axiosInstance.request({
    method: 'POST',
    url: `users/send_invitation_email`,  
    data: {
      visa_application_id: visaApplicationId,
    }
  });

  if (hasErrorStatus(response))
    throwRequestError('getInvitationToken - send_invitation_email', response.status);

  return updatedUser;
}

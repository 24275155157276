import {FunctionComponent, useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ReactComponent as PlusIcon } from '../../../assets/icon-plus.svg';
import DateSelectField from '../../../components/compound/DateSelectField';
import RadioField from '../../../components/compound/RadioField';
import SelectField from '../../../components/compound/SelectField';
import TextField from '../../../components/compound/TextField';
import FieldGroupNumber from '../../../components/display/FieldGroupNumber';
import Button from '../../../components/form/Button';
import FieldGroup from '../../../components/layout/FieldGroup';
import Section from '../../../components/layout/Section';
import { ALL_NATIONALITY_OPTIONS, RELATIONSHIP_OPTIONS_WITHOUT_SELF } from '../../../constants/options';
import { useValidation } from '../../../hooks';
import { ExtensionBySelfData } from '../../../types/extensionBySelf/data';
import { getLengthOfZincSnzkList  } from "../../../utils/visaFormDataHelper";
import { VisaApplicationType } from '../../../types/visa/applicationType';
import { ChangeBySelfData } from '../../../types/changeBySelf/data';
import { isThisVisaChange, isThisVisaExtension } from '../../../utils/visaApplicationHelper';
import { FamilyInJapanOrCoresidents, LivingTogetherWithThisFamilyOrCoresident } from '../../../types/visa/formCommonValues';
import { MAX_FAMILY_CORESIDENTS } from '../../../constants/values';
import InlineMessage from '../../../components/form/InlineMessage';

interface FamilyCoresidentsSectionProps {
    visaApplicationType: VisaApplicationType;
    extensionBySelfData: ExtensionBySelfData | null;
    changeBySelfData: ChangeBySelfData | null;
    onChangeExtensionBySelfData: (data: Partial<ExtensionBySelfData>) => void;
    onChangeChangeBySelfData: (data: Partial<ChangeBySelfData>) => void;
    onSectionReadinessChange: (isSectionReady: boolean) => void;
    isEmptyRequiredValueError: boolean;
}

type FamilyCoresident = {
    relationship: string;
    name: string;
    birthdate: string;
    nationality: string;
    livingTogether: string;
    employerSchoolName: string;
    residenceCardNumber: string;
}

const ButtonTextWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;


const FamilyCoresidentsSection: FunctionComponent<FamilyCoresidentsSectionProps> = ({
    visaApplicationType,
    extensionBySelfData,
    changeBySelfData,
    onChangeExtensionBySelfData,
    onChangeChangeBySelfData,
    onSectionReadinessChange,
    isEmptyRequiredValueError
}) => {
    const { t } = useTranslation('translation', { keyPrefix: 'familyCoresidentsSection' });
    const { t : tWithoutPrefix } = useTranslation();
    const validation = useValidation();
    const [familyCoresidentsPresent, setfamilyCoresidentsPresent] = useState('');
    const [familyCoresidents, setFamilyCoresidents] = useState<FamilyCoresident[]>([]);
    const [familyCoresidentNameErrors, setFamilyCoresidentNameErrors] = useState<string[]>(new Array(MAX_FAMILY_CORESIDENTS).fill(''));
    const [employmerSchoolNameErrors, setEmploymerSchoolNameErrors] = useState<string[]>(new Array(MAX_FAMILY_CORESIDENTS).fill(''));
    const [residenceCardNumberErrors, setResidenceCardNumberErrors] = useState<string[]>(new Array(MAX_FAMILY_CORESIDENTS).fill(''));
    const isVisaExtension = isThisVisaExtension(visaApplicationType);
    const isVisaChange = isThisVisaChange(visaApplicationType);
    const getEmptyError = (val: any) => (isEmptyRequiredValueError && !!!val)? tWithoutPrefix("inputAlert.field"): '';
    const emptyFamilyCoresident = {
        relationship: '',
        name: '',
        birthdate: '',
        nationality: '',
        livingTogether: '',
        employerSchoolName: '',
        residenceCardNumber: ''
    }
    const saveExtensionData = (data: Partial<ExtensionBySelfData>) => onChangeExtensionBySelfData(data);
    const saveChangeData = (data: Partial<ChangeBySelfData>) => onChangeChangeBySelfData(data);
    const addFamilyCoresident = () => setFamilyCoresidents([ ...familyCoresidents, { ...emptyFamilyCoresident }]);
    const updateFamilyCoresident = (index: number, fieldName: keyof FamilyCoresident, val: string) => {
        setFamilyCoresidents(
            familyCoresidents.map((familyCoresident, i) => i === index
                ? { ...familyCoresident, [fieldName]: val } 
                : familyCoresident
            )    
        );
    }
    const getEmptyfamilyCoresidentsFields = () => {      
        if (isVisaExtension) {
            let data: Partial<ExtensionBySelfData> = {}
            for (let i = 1; i <= MAX_FAMILY_CORESIDENTS; i++) {
                data = {
                    ...data,
                    [`WCIBS010Dto:zincSnzkList[${i}].selNationalityAndRegion`]: '',
                    [`WCIBS010Dto:zincSnzkList[${i}].txtName`]: '',
                    [`WCIBS010Dto:zincSnzkList[${i}].selDateOfBirthYear`]: '',
                    [`WCIBS010Dto:zincSnzkList[${i}].selDateOfBirthMonth`]: '',
                    [`WCIBS010Dto:zincSnzkList[${i}].selDateOfBirthDay`]: '',
                    [`WCIBS010Dto:zincSnzkList[${i}].selZkgr`]: '',
                    [`WCIBS010Dto:zincSnzkList[${i}].radDukyUm`]: '',
                    [`WCIBS010Dto:zincSnzkList[${i}].txtWorkPlaceOrTugkskName`]: '',
                    [`WCIBS010Dto:zincSnzkList[${i}].txtZiryCardNumOrTkeiNum`]: '',
                }
            }
            return data;
        }

        if (isVisaChange) {
            let data: Partial<ChangeBySelfData> = {}
            for (let i = 1; i <= MAX_FAMILY_CORESIDENTS; i++) {
                data = {
                    ...data,
                    [`WCIBS010Dto:zincSnzkList[${i}].selNationalityAndRegion`]: '',
                    [`WCIBS010Dto:zincSnzkList[${i}].txtName`]: '',
                    [`WCIBS010Dto:zincSnzkList[${i}].selDateOfBirthYear`]: '',
                    [`WCIBS010Dto:zincSnzkList[${i}].selDateOfBirthMonth`]: '',
                    [`WCIBS010Dto:zincSnzkList[${i}].selDateOfBirthDay`]: '',
                    [`WCIBS010Dto:zincSnzkList[${i}].selZkgr`]: '',
                    [`WCIBS010Dto:zincSnzkList[${i}].radDukyUm`]: '',
                    [`WCIBS010Dto:zincSnzkList[${i}].txtWorkPlaceOrTugkskName`]: '',
                    [`WCIBS010Dto:zincSnzkList[${i}].txtZiryCardNumOrTkeiNum`]: '',
                }
            }
            return data;
        }      
    }

    useEffect(() => {
        const fcs: FamilyCoresident[] = [];

        if (isVisaExtension && extensionBySelfData) {
            const data = extensionBySelfData;
            const length = getLengthOfZincSnzkList<ExtensionBySelfData>(data);
            setfamilyCoresidentsPresent(data["WCIBS010Dto:radZincSnzkUm1"]);

            for (let i = 1; i <= length; i++) {
                fcs.push({
                    relationship: data[`WCIBS010Dto:zincSnzkList[${i}].selZkgr` as keyof ExtensionBySelfData] as string ?? '',
                    name: data[`WCIBS010Dto:zincSnzkList[${i}].txtName` as keyof ExtensionBySelfData] as string ?? '',
                    birthdate: [
                        data[`WCIBS010Dto:zincSnzkList[${i}].selDateOfBirthYear` as keyof ExtensionBySelfData] as string,
                        data[`WCIBS010Dto:zincSnzkList[${i}].selDateOfBirthMonth` as keyof ExtensionBySelfData] as string,
                        data[`WCIBS010Dto:zincSnzkList[${i}].selDateOfBirthDay` as keyof ExtensionBySelfData] as string
                     ].filter(v => v).join("-") ,
                    nationality: data[`WCIBS010Dto:zincSnzkList[${i}].selNationalityAndRegion` as keyof ExtensionBySelfData] as string ?? '',
                    livingTogether: data[`WCIBS010Dto:zincSnzkList[${i}].radDukyUm` as keyof ExtensionBySelfData] as string ?? '',
                    employerSchoolName: data[`WCIBS010Dto:zincSnzkList[${i}].txtWorkPlaceOrTugkskName` as keyof ExtensionBySelfData] as string ?? '',
                    residenceCardNumber: data[`WCIBS010Dto:zincSnzkList[${i}].txtZiryCardNumOrTkeiNum` as keyof ExtensionBySelfData] as string ?? '',
                });
            }
        }
        
        if (isVisaChange && changeBySelfData) {
            const data = changeBySelfData;
            const length = getLengthOfZincSnzkList<ChangeBySelfData>(data);
            const fcs: FamilyCoresident [] = [];
            setfamilyCoresidentsPresent(data["WCIBS010Dto:radZincSnzkUm1"]);

            for (let i = 1; i <= length; i++) {
                fcs.push({
                    relationship: data[`WCIBS010Dto:zincSnzkList[${i}].selZkgr` as keyof ChangeBySelfData] as string ?? '',
                    name: data[`WCIBS010Dto:zincSnzkList[${i}].txtName` as keyof ChangeBySelfData] as string ?? '',
                    birthdate: [
                        data[`WCIBS010Dto:zincSnzkList[${i}].selDateOfBirthYear` as keyof ChangeBySelfData] as string,
                        data[`WCIBS010Dto:zincSnzkList[${i}].selDateOfBirthMonth` as keyof ChangeBySelfData] as string,
                        data[`WCIBS010Dto:zincSnzkList[${i}].selDateOfBirthDay` as keyof ChangeBySelfData] as string
                     ].filter(v => v).join("-") ,
                    nationality: data[`WCIBS010Dto:zincSnzkList[${i}].selNationalityAndRegion` as keyof ChangeBySelfData] as string ?? '',
                    livingTogether: data[`WCIBS010Dto:zincSnzkList[${i}].radDukyUm` as keyof ChangeBySelfData] as string ?? '',
                    employerSchoolName: data[`WCIBS010Dto:zincSnzkList[${i}].txtWorkPlaceOrTugkskName` as keyof ChangeBySelfData] as string ?? '',
                    residenceCardNumber: data[`WCIBS010Dto:zincSnzkList[${i}].txtZiryCardNumOrTkeiNum` as keyof ChangeBySelfData] as string ?? '',
                });
            } 
        }

        if (fcs.length === 0) {
            fcs.push({ ...emptyFamilyCoresident });
        }

        setFamilyCoresidents(fcs);
    }, [extensionBySelfData, changeBySelfData]);


    useEffect(() => {
        const areAllRequiredFieldsFilled = [
            familyCoresidentsPresent,
        ].every(val => !!val);
        
        const areThereNoErrors = [
            familyCoresidentNameErrors,
            employmerSchoolNameErrors,
            residenceCardNumberErrors
        ].every(errors => errors.every(err => !!!err));

        onSectionReadinessChange(
            areAllRequiredFieldsFilled && areThereNoErrors
        );
    }, [
        familyCoresidentsPresent,
        familyCoresidentNameErrors,
        employmerSchoolNameErrors,
        residenceCardNumberErrors
    ]);


    return (
        <Section>
            {/* "Do you have any family members in Japan or 
                are you living together with someone?"  */}
            <RadioField
                required
                label={t("familyInJapan")}
                options={[
                    {
                        label: tWithoutPrefix("common.yes"),
                        value: FamilyInJapanOrCoresidents.Present,
                    },
                    {
                        label: tWithoutPrefix("common.no"),
                        value: FamilyInJapanOrCoresidents.None,
                    },
                ]}
                value={familyCoresidentsPresent}
                error={getEmptyError(familyCoresidentsPresent)}
                note={t("noteOnFamily")}
                onValueChange={val => {
                    let additionalUpdateData = {};
                    setfamilyCoresidentsPresent(val);

                    if (val === FamilyInJapanOrCoresidents.None) 
                        additionalUpdateData = getEmptyfamilyCoresidentsFields() ?? {};

                    isVisaExtension && onChangeExtensionBySelfData({
                        "WCIBS010Dto:radZincSnzkUm1": val,
                        ...additionalUpdateData
                    })

                    isVisaChange && onChangeChangeBySelfData({
                        "WCIBS010Dto:radZincSnzkUm1": val,
                        ...additionalUpdateData
                    })
                                            
                }}
            />

            {familyCoresidentsPresent === FamilyInJapanOrCoresidents.Present 
                && familyCoresidents.map((familyCoresident, index) => (
                <FieldGroup key={index}>
                    <FieldGroupNumber>
                        {index + 1}
                    </FieldGroupNumber>

                    {/* Relationship */}
                    <SelectField
                        label={t("relationship")}
                        placeholder={tWithoutPrefix("placeholder.select")}
                        options={RELATIONSHIP_OPTIONS_WITHOUT_SELF}
                        value={familyCoresident.relationship}
                        onValueChange={val => {
                            updateFamilyCoresident(index, "relationship", val);
                            isVisaExtension && saveExtensionData({
                                [`WCIBS010Dto:zincSnzkList[${index + 1}].selZkgr`]: val
                            })
                            isVisaChange && saveChangeData({
                                [`WCIBS010Dto:zincSnzkList[${index + 1}].selZkgr`]: val
                            })
                        }}
                    />

                    {/* Name */}
                    <TextField
                        label={t("name")}
                        placeholder="グエン　ハイン"
                        value={familyCoresident.name}
                        error={familyCoresidentNameErrors[index]}
                        restriction={tWithoutPrefix("inputNote.fullwidthJapanese")}
                        maxLength={26}
                        note={t("noteOnNameOrder")}
                        validators={[validation.isFullwidth]}
                        onValueChange={val => updateFamilyCoresident(index, "name", val)}
                        onErrorChange={newErr => 
                            setFamilyCoresidentNameErrors(
                                familyCoresidentNameErrors.map((curErr, i) => index === i ? newErr : curErr)
                            )
                        }
                        onBlur={() => {
                            isVisaExtension && saveExtensionData({
                                [`WCIBS010Dto:zincSnzkList[${index + 1}].txtName`]: familyCoresident.name
                            })
                            
                            isVisaChange && saveChangeData({
                                [`WCIBS010Dto:zincSnzkList[${index + 1}].txtName`]: familyCoresident.name
                            })
                        }}
                    />

                    {/* Birthdate */}
                    <DateSelectField
                        label={t("birthdate")}
                        minDate={new Date(1930, 0, 1)}
                        maxDate={new Date()}
                        value={familyCoresident.birthdate}
                        onValueChange={val => {
                            updateFamilyCoresident(index, "birthdate", val)

                            if (!val) 
                                return;

                            const [year, month, day] = val.split('-');

                            isVisaExtension && saveExtensionData({
                                [`WCIBS010Dto:zincSnzkList[${index + 1}].selDateOfBirthYear`]: year,
                                [`WCIBS010Dto:zincSnzkList[${index + 1}].selDateOfBirthMonth`]: month,
                                [`WCIBS010Dto:zincSnzkList[${index + 1}].selDateOfBirthDay`]: day
                            })

                            isVisaChange && saveChangeData({
                                [`WCIBS010Dto:zincSnzkList[${index + 1}].selDateOfBirthYear`]: year,
                                [`WCIBS010Dto:zincSnzkList[${index + 1}].selDateOfBirthMonth`]: month,
                                [`WCIBS010Dto:zincSnzkList[${index + 1}].selDateOfBirthDay`]: day
                            })
                            
                        }}
                    />

                    {/* Nationality */}
                    <SelectField
                        label={t("nationality")}
                        placeholder={tWithoutPrefix("placeholder.select")}
                        options={ALL_NATIONALITY_OPTIONS}
                        value={familyCoresident.nationality}
                        onValueChange={val => {
                            updateFamilyCoresident(index, "nationality", val);

                            isVisaExtension && saveExtensionData({
                                [`WCIBS010Dto:zincSnzkList[${index + 1}].selNationalityAndRegion`]: val
                            })

                            isVisaChange && saveChangeData({
                                [`WCIBS010Dto:zincSnzkList[${index + 1}].selNationalityAndRegion`]: val
                            })
                        }}
                    />

                    {/* "Are you currently living together?" */}
                    <RadioField
                        label={t("livigingTogether")}
                        options={[
                            {
                                label: tWithoutPrefix("common.yes"),
                                value: LivingTogetherWithThisFamilyOrCoresident.Yes
                            },
                            {
                                label: tWithoutPrefix("common.no"),
                                value: LivingTogetherWithThisFamilyOrCoresident.No,
                            },
                        ]}
                        value={familyCoresident.livingTogether}
                        onValueChange={val => {
                            updateFamilyCoresident(index, "livingTogether", val);

                            isVisaExtension && saveExtensionData({
                                [`WCIBS010Dto:zincSnzkList[${index + 1}].radDukyUm`]: val
                            })

                            isVisaChange && saveChangeData({
                                [`WCIBS010Dto:zincSnzkList[${index + 1}].radDukyUm`]: val
                            })
                        }}
                    />

                    {/* Employer/school */}
                    <TextField
                        label={t("employmentOrSchool")}
                        placeholder="ビザダス株式会社"
                        value={familyCoresident.employerSchoolName}
                        error={employmerSchoolNameErrors[index]}
                        restriction={tWithoutPrefix("inputNote.fullwidthJapanese")}
                        maxLength={60}
                        validators={[validation.isFullwidth, validation.createLengthValidator(60)]}
                        onValueChange={val => updateFamilyCoresident(index, "employerSchoolName", val)}
                        onErrorChange={newErr => 
                            setEmploymerSchoolNameErrors(
                                employmerSchoolNameErrors.map((curErr, i) => index === i ? newErr : curErr)
                            )
                        }
                        onBlur={() => {
                            isVisaExtension && saveExtensionData({
                                [`WCIBS010Dto:zincSnzkList[${index + 1}].txtWorkPlaceOrTugkskName`]: familyCoresident.employerSchoolName
                            })

                            isVisaChange && saveChangeData({
                                [`WCIBS010Dto:zincSnzkList[${index + 1}].txtWorkPlaceOrTugkskName`]: familyCoresident.employerSchoolName
                            })     
                        }}
                    />

                    {/* Residence card number */}
                    <TextField
                        label={t("residenceCardNumber")}
                        placeholder="AAAA9999999C"
                        value={familyCoresident.residenceCardNumber}
                        error={residenceCardNumberErrors[index]}
                        restriction={tWithoutPrefix("inputNote.halfwidthLetterAndNumber")}
                        maxLength={12}
                        validators={[validation.isAlphanumeric, validation.createLengthValidator(12)]}
                        onValueChange={val => updateFamilyCoresident(index, "residenceCardNumber", val)}
                        onErrorChange={newErr => 
                            setResidenceCardNumberErrors(
                                residenceCardNumberErrors.map((curErr, i) => index === i ? newErr : curErr)
                            )
                        }
                        onBlur={() => {
                            isVisaExtension && saveExtensionData({
                                [`WCIBS010Dto:zincSnzkList[${index + 1}].txtZiryCardNumOrTkeiNum`]: familyCoresident.residenceCardNumber
                            })

                            isVisaChange && saveChangeData({
                                [`WCIBS010Dto:zincSnzkList[${index + 1}].txtZiryCardNumOrTkeiNum`]: familyCoresident.residenceCardNumber
                            })
                        }}
                    />
                </FieldGroup>
            ))}

            {familyCoresidentsPresent === FamilyInJapanOrCoresidents.Present && (
                (familyCoresidents.length >= MAX_FAMILY_CORESIDENTS) 
                    ? <>
                        <InlineMessage>
                            {t("reachedMaximumPersonNum")}
                        </InlineMessage>
                        <Button 
                            variant="inline" 
                            disabled={true}
                        >
                            <ButtonTextWrapper>
                                {t("addFamilyCoresident")}
                            </ButtonTextWrapper>
                        </Button>
                    </>  
                        
                    : <>
                        <Button 
                            variant="inline" 
                            onClick={addFamilyCoresident}
                        >
                            <ButtonTextWrapper>
                                <PlusIcon style={{ fontSize: 1 } }/>
                                {t("addFamilyCoresident")}
                            </ButtonTextWrapper>
                        </Button>
                    </>
            )}
        </Section>
    );
};

export default FamilyCoresidentsSection;
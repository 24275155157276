import { ExtensionBySelfData } from "../types/extensionBySelf/data";
import { axiosInstance, hasErrorStatus, throwRequestError } from "./common";

export const createExtensionBySelfData = async (visaApplicationId: number | string, data?: Partial<ExtensionBySelfData>) => {
  const response = await axiosInstance.request({
      method: 'POST',
      url: `koushin_honnins`,
      data: {
        ...data,
        visa_application_id: visaApplicationId
      }
  });

  if (hasErrorStatus(response)) 
    throwRequestError('createExtensionBySelfData', response.status);

  return response.data as ExtensionBySelfData;
};

export const getExtensionBySelfData = async (id: number | string) => {
  const response = await axiosInstance.request({
      method: 'GET',
      url: `koushin_honnins/${id}`
  });

  if (hasErrorStatus(response))
    throwRequestError('getExtensionBySelfData', response.status);

  return response.data as ExtensionBySelfData;
};


export const updateExtensionBySelfData = async (id: number | string, data: Partial<ExtensionBySelfData>) => {
  const response = await axiosInstance.request({
      method: 'PUT',
      url: `koushin_honnins/${id}`,
      data
  });

  if (hasErrorStatus(response))
    throwRequestError('updateExtensionBySelfData', response.status);

  return response.data as ExtensionBySelfData;
};

export const updateExtensionBySelfDataAsInvitee = async (id: number | string, data: Partial<ExtensionBySelfData>, invitationToken: string) => {
  const response = await axiosInstance.request({
      method: 'PUT',
      url: `koushin_honnins/${id}/invitee_update`,
      data: {
        ...data,
        invitation_token: invitationToken
      }
  });

  if (hasErrorStatus(response))
    throwRequestError('updateExtensionBySelfDataAsInvitee', response.status);

  return response.data as ExtensionBySelfData;
};

export const deleteExtensionBySelfData = async (id: number | string) => {
  const response = await axiosInstance.request({
      method: 'DELETE',
      url: `koushin_honnins/${id}`
  });

  if (hasErrorStatus(response))
    throwRequestError('deleteExtensionBySelfData', response.status); 

  return response.data as ExtensionBySelfData;
};



//For the original definitions, see https://docs.google.com/spreadsheets/d/1DSH9psUEakHitz56tuTrRxzV0y07nI_KhafCmHlUjCI/edit#gid=1606825711
//But, since we currently have to print out the values in a list form as is,
//we shouldn't use numerical codes and should use the following values instead.

export enum LocationOfFinalEducation {
  Japan = '本邦',
  ForeignCountry = '外国'
}

export enum WorkInInfoProcessing {
  Yes = 'はい',
  No = 'いいえ'
}

export enum ScheduledPeriodOfEmployment {
  Fixed = '定めあり',
  NonFixed = '定めなし'
}

export enum StartDateOfEmployment {
  Decided = '確定',
  Undecided = '未定'
}

export enum DoesWorkInvolveInInfoProcessing {
  Yes = 'はい',
  No = 'いいえ'
}

export enum DoesApplicantHavePositionOrTitle {
  Yes = 'はい',
  No = 'いいえ'
}

export enum TypeOfWorkChangedSinceLastVisaApplication {
  Yes = 'はい',
  No = 'いいえ'
}

//The following values are part of option values in this form.
//We use them to decide if we need to show some fileds or not.

export const FINAL_EDUCATION_OTHER_VALUE = 'その他'; 
export const FINAL_EDUCATION_DRMSBCJC_VALUES = ['大学院(博士)', '大学院(修士)', '大学', '短期大学'];
export const FINAL_EDUCATION_COLLEGE_OF_TECH_VALUE = '専門学校';

export const MAJOR_FIELD_OF_STUDY_FOR_DRMSBCJC_FOR_TECH_OTHER_VALUE = 'その他';
export const MAJOR_FIELD_OF_STUDY_FOR_COLLEGE_OF_TECH_FOR_TECHNICAL_OTHER_VALUE = 'その他';

export const INDUSTRY_OF_EMPLOYER_OTHER_VALUE = 'その他';
export const START_DATE_OF_EMPLOYMENT_UNDECIDED_OTHER_VALUE = 'その他';
import { FunctionComponent, useEffect, useState } from "react";
import FamilyVisaForm from "./VisaForms/FamilyVisaForm";
import Page from "../../components/layout/Page";
import Row from "../../components/layout/Row";
import Text from "../../components/text/Text";
import PageHeading from "../../components/text/PageHeading";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Button from "../../components/form/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatcher } from "../../hooks";
import { VisaApplicationType } from "../../types/visa/applicationType";
import { CSVSourceKind, FormKind, VisaApplication } from "../../types/visa/applicationData";
import { getVisaApplication, getVisaApplicationAsInvitee, updateVisaApplication } from "../../apis/visaApplication";
import { checkVisaApplicationType, getVisaInfoTransKeys, isNecessaryToWorkWithOrganization, isThisVisaApplicationSubmitted } from "../../utils/visaApplicationHelper";
import ApplicationSubmissionModal from "./ApplicationSubmissionModal";
import { clearURLParameters, extractURLParameters, isEnumValIncludedInURLParams } from "../../utils/utils";
import PaymentPromptModal from "../../components/modal/PaymentPromptModal";
import BackButton from "../../components/navigation/BackButton";
import { ReactComponent as OkIcon } from "../../assets/icon-check-circle-green-filled.svg";
import { ReactComponent as FailedIcon } from "../../assets/icon-cross-red.svg";
import { ReactComponent as SubmitIcon } from "../../assets/icon-send-white.svg";
import { ReactComponent as ShareIcon } from "../../assets/icon-share.svg";
import UserAuthenticationModal from "./UserAuthenticationModal";
import { NextAction as AuthModalAction } from "./UserAuthenticationModal";
import { NextAction as SubmissionModalAction } from "./ApplicationSubmissionModal";
import { VisaAttachment, VisaAttachmentKind } from "../../types/visa/attachment";
import { updateExtensionBySelfData, updateExtensionBySelfDataAsInvitee } from "../../apis/extensionBySelfData";
import { deleteVisaAttachment, deleteVisaAttachmentAsInvitee, deleteVisaAttachments, deleteVisaAttachmentsAsInvitee, uploadVisaAttachment, uploadVisaAttachmentAsInvitee } from "../../apis/visaAttachment";
import { ExtensionBySelfData } from "../../types/extensionBySelf/data";
import { ChangeBySelfData } from "../../types/changeBySelf/data";
import { updateChangeBySelfData, updateChangeBySelfDataAsInvitee } from "../../apis/changeBySelfData";
import Column from "../../components/layout/Column";
import Spinner from "../../components/display/Spinner";
import PreviewModal from "../../components/modal/previewModal";
import { getCurrentUser } from "../../apis/user";
import { isUserRegistrationApplicationApproved } from "../../utils/userRegistrationHelper";
import RasIDPasswordModal from "./RasIDPasswordModal";
import { getInvitationTokenFromURLParams, isSharedPageAccess } from "../../utils/invitationRelatedHelper";
import { FormN } from "../../types/uncommonFormParts/formN/data";
import { updateFormN, updateFormNAsInvitee } from "../../apis/formN";
import TechnicalVisaForm from "./VisaForms/TechnicalVisaForm";
import UserInvitationModal from "../../components/modal/UserInvitationModal";
import { SupplementaryInfo } from "../../types/uncommonFormParts/supplementaryInfo";
import { updateSupplementaryInfo, updateSupplementaryInfoAsInvitee } from "../../apis/supplementaryInfo";
import axios from "axios";

interface ApplicationFormPageProps {}

const HeaderContainer = styled(Column)`
  padding: 20px;
  gap: 30px;
`;

const LocalPageHeading = styled(PageHeading)`
  font-size: 26px;
`;

const NavStatusContainer = styled(Row)`
  justify-content: space-between;
  gap: 15px;
`;

const ShareButtonContainer = styled(Row)`
`;

const ShareButton = styled(Button).attrs(() => ({
  variant: 'inline',
}))`
  width: auto;
  min-width: 109px;
`;

const HeadingButtonContainer = styled(Row)`
  justify-content: space-between;
`;

const AutoSaveStatusText = styled(Text)`
  color: #626161;
  gap: 10px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  padding: 50px 20px 20px 20px;
  box-sizing: border-box;
`;

const PreviewButton = styled(Button).attrs(() => ({
  variant: 'primary',
}))`
  min-width: 120px;;
  width: auto;
`;

const SubmitButton = styled(Button).attrs(() => ({
  variant: 'primary',
}))`
  min-width: 120px;;
  width: auto;
  gap: 10px;
`;
          

const ApplicationFormPage: FunctionComponent<ApplicationFormPageProps> = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { state, dispatcher } = useDispatcher();
  const [visaApplicationType, setVisaApplicationType] = useState<VisaApplicationType>();
  const [visaApplication, setVisaApplication] = useState<VisaApplication>();
  const [extensionBySelfData, setExtensionBySelfData] = useState<ExtensionBySelfData | null>(null);
  const [changeBySelfData, setChangeBySelfData] = useState<ChangeBySelfData | null>(null);
  const [supplementaryInfo, setSupplementaryInfo] = useState<SupplementaryInfo | null>(null);
  const [formN, setFormN] = useState<FormN | null>(null); 
  const [visaAttachments, setVisaAttachments] = useState<VisaAttachment[]>([]);
  const [paymentUrl, setPaymentUrl] = useState<string>("");
  const [hasDataBeenAutoSavedAtLeastOnce, setHasDataBeenAutoSavedAtLeastOnce] = useState<boolean>(false);
  const [haveAttachmentsBeenUpdatedAtLeastOnce, setHaveAttachmentsBeenUploadedAtLeastOnce] = useState<boolean>(false);
  
  //Some visas require to work with the user's organization
  //They are invited and won't have its own account.
  //These are to control the flow of the application
  const isThisSharedPageAccess = isSharedPageAccess();
  const [isNecessaryToWorkWithOrg, setIsNecessaryToWorkWithOrg] = useState<boolean>(false); 
  const invitationToken = getInvitationTokenFromURLParams();

  //If true, the form shows alert icons next to the sections that have errors
  //In addition, empty required fields are treated as errors
  //(This is triggered by the user clicking the submit button)
  const [showAllErrorAlerts, setShowAllErrorAlerts] = useState<boolean>(false);

  const [hasFailedToSave, setHasFailedToSave] = useState<boolean>(false);
  const [hasUserPaid, setHasUserPaid] = useState<boolean>(false); 
  const [showSubmissionModal, setShowSubmissionModal] = useState<boolean>(false);
  const [showPaymentPromptModal, setShowPaymentPromptModal] = useState<boolean>(false);
  const [showUserAuthenticationModal, setShowUserAuthenticationModal] = useState<boolean>(false);
  const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);
  const [showRasIdPasswordModal, setShowRasIdPasswordModal] = useState<boolean>(false);
  const [showUserInvitationModal, setShowUserInvitationModal] = useState<boolean>(false);
  const [isApplicationReadyToSubmit, setIsApplicationReadyToSubmit] = useState<boolean>(false);


  const saveExtensionBySelfData = async (data: Partial<ExtensionBySelfData>) => {
    if (visaApplication?.csv_source_kind !== CSVSourceKind.ExtensionBySelf)
      throw new Error("The CSV source kind doesn't match the target data type that is being saved");

    try {
      setHasFailedToSave(false);
      dispatcher.startSaving();
      const id = visaApplication!.koushin_honnin!.id;
      const updatedExtensionBySelfData: ExtensionBySelfData = 
        isThisSharedPageAccess 
          ? await updateExtensionBySelfDataAsInvitee(id, data, invitationToken!)
          : await updateExtensionBySelfData(id, data);
      setExtensionBySelfData(updatedExtensionBySelfData);
      setVisaApplication({
        ...visaApplication!,
        koushin_honnin: updatedExtensionBySelfData
      })
      setHasDataBeenAutoSavedAtLeastOnce(true);
    } catch (e) {
      setHasFailedToSave(true);
    } finally {
      dispatcher.endSaving();
    }
  }

  const saveChangeBySelfData = async (data: Partial<ChangeBySelfData>) => {
    if (visaApplication?.csv_source_kind !== CSVSourceKind.ChangeBySelf)
      throw new Error("The CSV source kind doesn't match the target data type that is being saved");

    try {
      setHasFailedToSave(false);
      dispatcher.startSaving();
      const id = visaApplication!.henkou_honnin!.id;
      const updatedChangeBySelfData: ChangeBySelfData = 
        isThisSharedPageAccess
          ? await updateChangeBySelfDataAsInvitee(id, data, invitationToken!)
          : await updateChangeBySelfData(id, data);
      setChangeBySelfData(updatedChangeBySelfData);
      setVisaApplication({
        ...visaApplication!,
        henkou_honnin: updatedChangeBySelfData
      })
      setHasDataBeenAutoSavedAtLeastOnce(true);
    } catch (e) {
      setHasFailedToSave(true);
    } finally {
      dispatcher.endSaving();
    }
  }

  const saveFormNData = async (data: Partial<FormN>) => {
    if (visaApplication?.form_kind !== FormKind.N) 
      throw new Error("The form kind doesn't match the target data type that is being saved");

    try {
      setHasFailedToSave(false);
      dispatcher.startSaving();
      const id = visaApplication!.form_n!.id;
      const updatedFormNData: FormN = 
        isThisSharedPageAccess
          ? await updateFormNAsInvitee(id, data, invitationToken!)
          : await updateFormN(id, data);
      setFormN(updatedFormNData);
      setVisaApplication({
        ...visaApplication!,
        form_n: updatedFormNData
      })
    } catch (e) {
      setHasFailedToSave(true);
    } finally {
      dispatcher.endSaving();
    }
  }

  const saveSupplementaryInfo = async (data: Partial<SupplementaryInfo>) => {
    try {
      setHasFailedToSave(false);
      dispatcher.startSaving();
      const id = visaApplication!.supplementary_info!.id;
      const updatedSupplementaryInfo: SupplementaryInfo = 
        isThisSharedPageAccess
          ? await updateSupplementaryInfoAsInvitee(id, data, invitationToken!)
          : await updateSupplementaryInfo(id, data);
      setVisaApplication({
        ...visaApplication!,
        supplementary_info: updatedSupplementaryInfo
      })
    } catch (e) {
      setHasFailedToSave(true);
    } finally {
      dispatcher.endSaving();
    }
  }

  const saveVisaAttachment = async (file: File, kind: VisaAttachmentKind, uploaderId?: string) => {
    try {
      setHasFailedToSave(false);
      dispatcher.startUploading(uploaderId);
      const uploadedVisaAttachment =
        isThisSharedPageAccess
          ? await uploadVisaAttachmentAsInvitee(visaApplication!.id, file, kind, invitationToken!)
          : await uploadVisaAttachment(visaApplication!.id, file, kind);
      const newAttachments = [...visaAttachments, uploadedVisaAttachment];

      setVisaAttachments(newAttachments);
      setVisaApplication({
        ...visaApplication!,
        visa_attachments: newAttachments
      });
      setHaveAttachmentsBeenUploadedAtLeastOnce(true);
    } catch (e) {
      setHasFailedToSave(true);
    } finally {
      dispatcher.endUploading(uploaderId);
    }
  }

  const removeVisaAttachment = async (visaAttachmentId: number) => {
    try {
      dispatcher.startSaving();

      if (isThisSharedPageAccess)
        await deleteVisaAttachmentAsInvitee(visaAttachmentId, invitationToken!);
      else
        await deleteVisaAttachment(visaAttachmentId);
  
      const newAttachments = visaAttachments.filter(va => va.id !== visaAttachmentId);

      setVisaAttachments(newAttachments);
      setVisaApplication({
        ...visaApplication!,
        visa_attachments: newAttachments
      });
      setHaveAttachmentsBeenUploadedAtLeastOnce(true);
    } catch (e) {
    } finally {
      dispatcher.endSaving();
    }
  }

  const removeVisaAttachments = async (visaAttachmentIds: number[]) => {
    if (visaAttachmentIds.length === 0)
      return;

    try {
      dispatcher.startSaving();

      if (isThisSharedPageAccess)
        await deleteVisaAttachmentsAsInvitee(visaAttachmentIds, invitationToken!);
      else
        await deleteVisaAttachments(visaAttachmentIds);
       
      const newAttachments = visaAttachments.filter(va => !visaAttachmentIds.includes(va.id));

      setVisaAttachments(newAttachments);
      setVisaApplication({
        ...visaApplication!,
        visa_attachments: newAttachments
      });
      setHaveAttachmentsBeenUploadedAtLeastOnce(true);
    } catch (e) {
    } finally {
      dispatcher.endSaving();
    }
  }

  const onSubmit = () => {
    //Enable verify_to_submit to show all errors (even after logout and login again)
    if (!!!visaApplication?.verify_to_submit) {
      (async () => {
        try {
          updateVisaApplication(id!, { verify_to_submit: true });
        } catch (e) {
        } finally {
          setShowAllErrorAlerts(true);
        }
      })();
    }
    
    if (!isApplicationReadyToSubmit) {
      setShowAllErrorAlerts(true);
      dispatcher.showSnackbar(t("snackbar.enterAllFields"), 'warning');
      return;
    }

    if (!hasUserPaid) {
      setShowPaymentPromptModal(true);
      return;
    }
    
    if (!isUserRegistrationApplicationApproved(state.userRegistrationAppStatus)) {
      setShowUserAuthenticationModal(true)
      return;
    }

    //Submission modal is shown after the user confirms the data on the preview modal
    setShowPreviewModal(true);
  };

  const onPreviewByOrganization = () => {
    if (!isApplicationReadyToSubmit) {
      setShowAllErrorAlerts(true);
      dispatcher.showSnackbar(t("snackbar.enterAllFields"), 'warning');
      return;
    }

    setShowPreviewModal(true);
  }

  useEffect(() => {
    (async () => {
      try {    
        dispatcher.startLoading();    

        //From here, the code is for the normal user
        const visaApplication = 
          isThisSharedPageAccess 
            ? await getVisaApplicationAsInvitee(id!, invitationToken!)
            : await getVisaApplication(id!);
        const visaApplicationType = checkVisaApplicationType(visaApplication);
        const isThisVisaNecessaryToWorkWithOrg = isNecessaryToWorkWithOrganization(visaApplication);
        
        if (isThisVisaApplicationSubmitted(visaApplication)) {
          const params = isThisSharedPageAccess ? `?invitation_token=${invitationToken}` : '';
          const redirectPath = `/application/${visaApplication.id}/review${params}`;
          navigate(redirectPath);
          return;        
        }
        
        setVisaApplication(visaApplication);
        setVisaApplicationType(visaApplicationType);
        setExtensionBySelfData(visaApplication.koushin_honnin);
        setChangeBySelfData(visaApplication.henkou_honnin);
        setSupplementaryInfo(visaApplication.supplementary_info);
        setVisaAttachments(visaApplication.visa_attachments);
        
        switch (visaApplication.form_kind) {
          case FormKind.N:
            setFormN(visaApplication.form_n);
            break;
        }

        //This normally shouldn't happen
        //If it does, then it can be a bug or a malicious access
        if (isThisSharedPageAccess && !isThisVisaNecessaryToWorkWithOrg)
          throw new Error("Unexpected way to access the shared page");

        //The remaining code is for the normal user so don't do anything for the invited user
        if (isThisSharedPageAccess)
          return;

        const user = await getCurrentUser();

        setHasUserPaid(user.is_paid);
        setPaymentUrl(user.stripe_invoice_url);
        setShowAllErrorAlerts(visaApplication.verify_to_submit ?? false);
        setIsNecessaryToWorkWithOrg(isThisVisaNecessaryToWorkWithOrg);

        if (isEnumValIncludedInURLParams(AuthModalAction)) {
          setShowUserAuthenticationModal(true);   
          return;
        }

        if (isEnumValIncludedInURLParams(SubmissionModalAction)) {
          //Prevent the user from submitting the application if the user hasn't paid
          //(This can happen if the user directly enters the comeback URL)
          if (!user.is_paid) {
            clearURLParameters();
            setShowPaymentPromptModal(true);
            return;
          }

          setShowSubmissionModal(true);
          return;
        }
      } catch (e) {
        if (axios.isAxiosError(e)) {
          switch (e.response?.status) {
            case 403:
              isThisSharedPageAccess && navigate("shared/invalid");
          }

          navigate("/");
        }
      } finally {
        dispatcher.stopLoading();
      }
    })();
  }, []);

  return (
    <Page>
      <HeaderContainer>
        <NavStatusContainer>
          <BackButton 
            onClick={() => navigate("/")}
            style={{
              visibility: isThisSharedPageAccess ? 'hidden' : 'visible'
            }}
          />     
          <AutoSaveStatusText>
            {/* Auto-save : *Initial state (show a note on auto-save for the user) */}
            { (!hasDataBeenAutoSavedAtLeastOnce && !haveAttachmentsBeenUpdatedAtLeastOnce) && 
              <>{t("applicationFormPage.autoSave.willBeAutoSaved")}</>
            }

            {/* Auto-save : Saving */}
            { ((hasDataBeenAutoSavedAtLeastOnce && state.isSaving) || 
               (haveAttachmentsBeenUpdatedAtLeastOnce && state.isUploading)) &&
              <><Spinner />{t("applicationFormPage.autoSave.saving")}</>
            }
              
            {/* Auto-save : Saved */}
            { ((hasDataBeenAutoSavedAtLeastOnce && !state.isSaving && !hasFailedToSave) || 
               (haveAttachmentsBeenUpdatedAtLeastOnce && !state.isUploading && !hasFailedToSave)) &&
              <><OkIcon />{t("applicationFormPage.autoSave.saved")}</>
            }

            {/* Auto-save : Failed to save */}
            { ((hasFailedToSave && !state.isSaving)) &&
              <><FailedIcon style={{ width: 20, height: 20 }}/>{t("applicationFormPage.autoSave.failed")}</>
            }
          </AutoSaveStatusText>
        </NavStatusContainer>
        { !isThisSharedPageAccess && isNecessaryToWorkWithOrg &&
          <ShareButtonContainer>
            <ShareButton onClick={() => setShowUserInvitationModal(true)}>
              <Row style={{gap: 10}}>
                <ShareIcon />  
                Share
              </Row>
            </ShareButton>
          </ShareButtonContainer>
        }
        <HeadingButtonContainer>
          <LocalPageHeading>
            { (visaApplication && getVisaInfoTransKeys(visaApplication).type) &&
              t(`applicationFormPage.title.${getVisaInfoTransKeys(visaApplication).type}`) 
            }
          </LocalPageHeading>
          { isThisSharedPageAccess
            ? <PreviewButton onClick={onPreviewByOrganization} >
                {t("common.preview")}
              </PreviewButton>
            : <SubmitButton onClick={onSubmit}>
                <SubmitIcon />
                {t("common.submit")}
              </SubmitButton>
          }
        </HeadingButtonContainer>
      </HeaderContainer>

      {/* Actual Form body */}
      <div>
        {(() => {
          switch (visaApplicationType) {
            case VisaApplicationType.ExtendFamilyVisaBySelf:
              return (
                <FamilyVisaForm 
                  visaApplicationType={visaApplicationType}
                  extensionBySelfData={extensionBySelfData}
                  changeBySelfData={changeBySelfData}
                  visaAttachments={visaAttachments}              
                  onChangeBySelfDataChange={saveChangeBySelfData}
                  onExtensionBySelfDataChange={saveExtensionBySelfData}
                  onApplicationReadinessChange={setIsApplicationReadyToSubmit}
                  onVisaAttachmentUpload={saveVisaAttachment}
                  onVisaAttachmentDelete={removeVisaAttachment}
                  showErrorAlerts={showAllErrorAlerts}
                />
                );
            case VisaApplicationType.ExtendTechnicalVisaBySelf:
              return (
                <TechnicalVisaForm 
                  visaApplicationType={visaApplicationType}
                  extensionBySelfData={extensionBySelfData}
                  changeBySelfData={changeBySelfData}
                  formN={formN}
                  supplementaryInfo={supplementaryInfo}
                  visaAttachments={visaAttachments}              
                  onChangeBySelfDataChange={saveChangeBySelfData}
                  onExtensionBySelfDataChange={saveExtensionBySelfData}
                  onChangeFormN={saveFormNData}
                  onChangeSupplementaryInfo={saveSupplementaryInfo}
                  onApplicationReadinessChange={setIsApplicationReadyToSubmit}
                  onVisaAttachmentUpload={saveVisaAttachment}
                  onVisaAttachmentDelete={removeVisaAttachment}
                  onVisaAttachmentsDelete={removeVisaAttachments}
                  showErrorAlerts={showAllErrorAlerts}
                />
              )
            default:
              return null;
            }
          })()}
      </div>

      <ButtonWrapper>
        { isThisSharedPageAccess
          ? <PreviewButton onClick={onPreviewByOrganization} style={{ width: '100%'}}>
              {t("common.preview")}
            </PreviewButton>
          : <SubmitButton onClick={onSubmit} style={{ width: '100%' }}>
              <SubmitIcon />
              {t("common.submit")}
            </SubmitButton>
        }
      </ButtonWrapper>

      { showPaymentPromptModal &&
        <PaymentPromptModal 
          promptType="required"
          paymentUrl={paymentUrl}        
          onClose={() => setShowPaymentPromptModal(false)} 
        />
      }

      { showSubmissionModal && visaApplication &&
        <ApplicationSubmissionModal 
          visaApplication={visaApplication}
          params={extractURLParameters()}
          onClose={() => setShowSubmissionModal(false)} 
          onSubmissionComplete={() => {
            setShowSubmissionModal(false);
            navigate(`application/${id}/review`) 
          }}
        />
      }

      {/* User Authentication modal does two things
          1. Have the user confirm the current user registration status
          2. Ask the user to input RAS password and ID 
          
          The user can skip #1 if the user has already confirmed in the past
        */}
      { showUserAuthenticationModal && visaApplication &&
        <UserAuthenticationModal 
          visaApplicationId={visaApplication.id} 
          currentAppStatus={state.userRegistrationAppStatus}
          onClose={() => setShowUserAuthenticationModal(false)}    
          onClickNext={() => {
            setShowUserAuthenticationModal(false);
            setShowPreviewModal(true);
          }}
          onConfirmAuthStatus={status => {
            dispatcher.setUserRegistrationAppStatus(status);
          }}
        />
      }

      { showPreviewModal && visaApplication &&
        <PreviewModal 
          visaApplication={visaApplication}
          title={t("modalPreview.forApplicationFormPage.title")}
          description={
            isThisSharedPageAccess
            ? ""
            : t("modalPreview.forApplicationFormPage.description")
          }
          warningText={
            isThisSharedPageAccess
            ? ""
            : t("modalPreview.forApplicationFormPage.warning")
          }
          buttonText={
            isThisSharedPageAccess 
              ? t("common.close")
              : t("common.confirm")
          }
          buttonVariant={
            isThisSharedPageAccess
              ? "inline"
              : "primary"
          }
          onClickButton={() => {
            setShowPreviewModal(false);

            if (!isThisSharedPageAccess)
              setShowRasIdPasswordModal(true);
          }}
          onClickBackIcon={() => setShowPreviewModal(false)}
      />
      }
      
      { showRasIdPasswordModal && 
        <RasIDPasswordModal
          onClose={() => setShowRasIdPasswordModal(false)}
          onBack={() => {
            setShowRasIdPasswordModal(false);
            setShowPreviewModal(true)
          }}
          onSave={() => {
            setShowSubmissionModal(true)
            setShowRasIdPasswordModal(false);
          }}
        />
      }

      { showUserInvitationModal && visaApplication &&
        <UserInvitationModal
          visaApplicationId={visaApplication.id}
          onBack={() => setShowUserInvitationModal(false)}
        />
      }
      
    </Page>
  );
};

export default ApplicationFormPage;

//Ref: https://docs.google.com/spreadsheets/d/1DSH9psUEakHitz56tuTrRxzV0y07nI_KhafCmHlUjCI/edit#gid=1263905895
export enum Gender {
  Male = '1',
  Female = '2'
}

//For "Marital Status"
export enum MaritalStatus {
  Single = '0',
  Married = '1'
}


//Ref: https://docs.google.com/spreadsheets/d/1DSH9psUEakHitz56tuTrRxzV0y07nI_KhafCmHlUjCI/edit#gid=1813510246
//For "Do you have any criminal record either in Japan or overseas"
export enum CrimePunishmentStatus {
  None = '0',
  Punished = '1'
}

//For "Do you have any family members in Japan or are you living together with someone"
export enum FamilyInJapanOrCoresidents {
  None = '0',
  Present = '1'
}

//For "Are you currently living together" in Family/Coresidents
export enum LivingTogetherWithThisFamilyOrCoresident {
  No = '0',
  Yes = '1'
}

//For "Method of receiving new residence card"
export enum HowToReceiveNewResidenceCard {
  Mail = "01",
  VisitImmigrationOffice = "02"
}
import { FunctionComponent, HTMLAttributes } from 'react';
import styled from 'styled-components';

import ErrorMessage from '../display/ErrorMessage';
import Restriction from '../display/Restriction';
import InlineMessage from '../form/InlineMessage';
import Label from '../form/Label';
import Row from '../layout/Row';
import Select from '../form/Select';
import { useTranslation } from 'react-i18next';

import { TIME_PERIOD_YEAR_OPTIONS, TIME_PERIOD_MONTH_OPTIONS } from '../../constants/options/timePeriod';

interface TimePeriodSelectFieldProps extends HTMLAttributes<HTMLDivElement> {
    disabled?: boolean;
    required?: boolean;
    label?: string;
    yearValue?: string;
    monthValue?: string;
    error?: string;
    restriction?: string;
    note?: string;
    onYearValueChange?: (value: string) => void;
    onMonthValueChange?: (value: string) => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const LocalRow = styled(Row)`
  gap: 10px;  
`;

const TimePeriodSelectField: FunctionComponent<TimePeriodSelectFieldProps> = ({
    disabled,
    required,
    label,
    yearValue,
    monthValue,
    error,
    restriction,
    note,
    onYearValueChange,
    onMonthValueChange,
}) => {
    const { t } = useTranslation('translation', { keyPrefix: 'placeholder' });

    return (
        <Container>
            {label && (
                <Label required={required}>
                    {label}
                </Label>
            )}
            <LocalRow>
                <Select
                    disabled={disabled}
                    invalid={!!error && !yearValue}
                    placeholder={t("years")}
                    options={TIME_PERIOD_YEAR_OPTIONS}
                    value={yearValue || ''}
                    onValueChange={onYearValueChange}
                />
                <Select
                    disabled={disabled}
                    invalid={!!error && !monthValue}
                    placeholder={t("months")}
                    options={TIME_PERIOD_MONTH_OPTIONS}
                    value={monthValue || ''}
                    onValueChange={onMonthValueChange}
                />
            </LocalRow>
            {error && (
                <ErrorMessage>
                    {error}
                </ErrorMessage>
            )}
            {restriction && (
                <Restriction content={restriction} />
            )}
            {note && (
                <InlineMessage variant='info' size="small">
                    {note}
                </InlineMessage>
            )}
        </Container>
    );
};

export default TimePeriodSelectField;